import { IAuthSharedBusinessLogic } from "./sbl-auth-interfaces";
import { AuthData, AuthService } from "./auth-service";

const getAuthSBLInstance: () => IAuthSharedBusinessLogic = window.av8.auth.getAuthSBLInstance;

export class SBLAuthService implements AuthService {
  private data: AuthData | null = null;
  private listeners = new Set<(data: AuthData | null) => void>();

  constructor() {
    const sbl = getAuthSBLInstance();
    sbl.getCredentials().then(cred => {
      this.storeDataOrNull(cred);
    });

    sbl.addOnAuthChangeListener(async (data: any) => {
      this.storeDataOrNull(data);
    });
  }

  private storeDataOrNull(data: { agentId?: string | null, token?: string | null } | null) {
    const currentData = this.data;
    let newData: any = null;
    if (data != null && data.token && data.agentId) {
      newData = { token: data.token, agentId: data.agentId };
    }
    else {
      newData = null;
    }
    if (JSON.stringify(currentData) !== JSON.stringify(newData)) {
      this.data = newData;
      this.listeners.forEach(listener => listener(this.data));
    }
  }

  getCredentials(): AuthData | null {
    return this.data;
  }

  addListener(listener: (data: AuthData | null) => void) {
    this.listeners.add(listener);
  }

  removeListener(listener: (data: AuthData | null) => void) {
    this.listeners.delete(listener);
  }

  async getCredentialsAsync(): Promise<AuthData | null> {
    const data = await getAuthSBLInstance().getCredentials();
    this.storeDataOrNull(data);
    return this.data;
  }

  signOut() {
    return getAuthSBLInstance().signOut();
  }

  requestAuthentication() {
    getAuthSBLInstance().requestAuthentication(true);
  }
}