import * as React from 'react';
import { ConfirmDialog } from "../../../shared/components/ConfirmDialog"

export interface SwitchIsNewClientDialogProps {
  onConfirm: () => void;
  onCancel: () => void;
  open: boolean;
  isNewCurrent: boolean;
}

export const SwitchIsNewClientDialog = ({ isNewCurrent, onConfirm, onCancel, open }: SwitchIsNewClientDialogProps) => {
  let dialogTitle: string = "Confirm?";
  let dialogMessage: string;
  const [isNewCurrentState, setIsNewCurrentState] = React.useState(isNewCurrent);
  if (isNewCurrentState) {
    dialogMessage = "Are you sure you do not want to save your new client?";
  }
  else {
    dialogMessage = "Adding a new client will remove the selected existing client from this request. Are you sure you want to add a new client?"
  }

  return <ConfirmDialog
    onEntering={() => {
      setIsNewCurrentState(isNewCurrent);
    }}
    open={open}
    dialogTitle={dialogTitle}
    dialogMessage={dialogMessage}
    onConfirm={onConfirm}
    onCancel={onCancel}
  />
}