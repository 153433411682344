import * as React from "react";

let googleMapsApiPromise: Promise<void> | null = null;
let googleMapsApiReady = false;

export const useGoogleMapsApi = (
  { language }: { language: string } = { language: "en" }
) => {
  const [ready, setReady] = React.useState(googleMapsApiReady);
  React.useEffect(() => {
    if (document.getElementById("maps.googleapis.com") == null && !googleMapsApiReady) {

      if(!googleMapsApiPromise){
        googleMapsApiPromise = new Promise((resolve, reject)=>{
          const script = document.createElement("script");
          script.id = "maps.googleapis.com";
          script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyB_d6NnnYOKyD4v0I2xhJZtDE33YH0xUVE&libraries=places&language=${language}`;
          script.async = true;
          script.onload = () => {
            console.log("Google Maps API Loaded");
            resolve();
          };
          script.onerror = () => {
            reject();
          }
          document.body.appendChild(script);
        });
        googleMapsApiPromise.catch(()=>{
          googleMapsApiPromise = null;
        })
      }
      googleMapsApiPromise.then(()=>{
        setReady(true);
      })
      
    } else {
      setReady(true);
      googleMapsApiReady = true;
    }
  }, [language]);
  return ready;
};
