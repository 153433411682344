import { ButtonRadioGroup, ButtonRadioGroupProps, InputContainer } from "av8-ui";
import { ControllerRenderProps, DeepMap, FieldError } from "react-hook-form";
import { getErrorMessage } from "./getErrorMessage";

export const ButtonRadioGroupRenderer = ({
  buttonRadioGroupProps, errors, errorMessage, label, notifyChange, name
}: {
  notifyChange?: () => void,
  errorMessage?: string,
  errors?: DeepMap<Record<string, any>, FieldError>,
  buttonRadioGroupProps: ButtonRadioGroupProps,
  label?: string | React.ReactNode,
  name: string
}) => (props: ControllerRenderProps<Record<string, any>>) => {
  const error = errors ? getErrorMessage(props.name, errors) : undefined;

  return (
    <InputContainer label={label} padding="normal" error={error} data-meta-type="button-radio-group" data-meta-name={name}>
      <ButtonRadioGroup
        initialValue={props.value}
        {...buttonRadioGroupProps}
        onChange={(value) => { props.onChange(value); notifyChange?.(); }}
      />
    </InputContainer>
  );
}