import { getQueryObject } from "../shared/getQueryObject";

const qs = getQueryObject(window.location.search);

function getFlag(key: string, defaultValue: boolean, queryStringChange: 'override-any' | 'true-only' | 'false-only'): boolean {
    let value = defaultValue;
    const envValue = process.env[`REACT_APP_${key}`];
    if (envValue === '1') value = true;
    if (envValue === '0') value = false;
    const qsValue = qs[key];
    if (qsValue === '1' && queryStringChange !== 'false-only') value = true;
    if (qsValue === '0' && queryStringChange !== 'true-only') value = false;
    return value;
}

export const ENABLE_CLIENT_DROPDOWN = getFlag("FLAG_ENABLE_CLIENT_DROPDOWN", true, 'true-only');
