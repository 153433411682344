import { A8Button } from "av8-ui";
import { Controller, useForm } from "react-hook-form";
import { RequestFlowControls, RequestFlowStepProps } from "../RequestFlow";
import { ButtonRadioGroupRenderer } from "./buttonRadioGroupRender";
import { RequiredSymbol } from "./RequiredSymbol";
import { StepTitle } from "./StepTitle";


type FormData = {
  propertyRenovatedState?: string;
}

export const SetPropertyRenovatedStep = (props: RequestFlowStepProps) => {

  const flowState = props.lastSentState ?? props.state;
  const initialValues: FormData = {
    propertyRenovatedState: flowState.propertyRenovatedState
  };

  const { handleSubmit, control, errors, getValues } = useForm();

  const getModifiedFlowState = (data: FormData) => {
    const { propertyRenovatedState } = data;
    return { ...props.state, propertyRenovatedState };
  }

  const onSubmit = (data: FormData) => {
    props.moveTo("setCompsPreferences", getModifiedFlowState(data));
  };

  const submitHandler = handleSubmit(onSubmit);

  const controls = <RequestFlowControls {...props} extractStateBeforeBack={() => getModifiedFlowState(getValues())} nextButton={<A8Button wide={true} variant="contained" type="submit" data-meta-action="next">Next</A8Button>} />;

  return (<form onSubmit={submitHandler} noValidate data-meta-name="step-5">
    {controls}
    <StepTitle primaryText="*Is the* property renovated?" />

    <Controller name="propertyRenovatedState"
      defaultValue={initialValues.propertyRenovatedState}
      rules={{ required: true }} control={control}
      render={ButtonRadioGroupRenderer({
        errors, label: <span>Select one <RequiredSymbol /></span>, buttonRadioGroupProps: {
          options: [
            ['complete-remodel', 'Complete Remodel'],
            ['cosmetic', 'Cosmetic'],
            ['not-updated', 'Not Updated'],
            ['fixer-upper', 'Fixer-Upper'],
            ['not-sure', 'I’m not sure']
          ].map(([value, label]) => ({ label, value })),
        },
        name: "propertyRenovatedState"
      })} />
  <br />
  {controls}

  </form>);
}