import * as React from 'react';
import Autocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import usePlacesAutocomplete, { getGeocode, getZipCode } from 'use-places-autocomplete';
import { TextField, TextFieldProps } from '@material-ui/core';
import { useGoogleMapsApi } from './useGoogleMapsApi';

interface Props {
  textFieldProps: TextFieldProps;
  onAddressFound: (data: { terms?: string[], city?: string, state?: string, country?: string, zipCode?: string | null }) => void;
}

export const AddressTextField = React.forwardRef((props: Props, ref) => {
  const mapsready = useGoogleMapsApi();
  if (!mapsready) {
    return (
      <TextField {...props.textFieldProps} />
    );
  }
  return (
    <Inner {...props} ref={ref} />
  );
});

const Inner = React.forwardRef(({ textFieldProps, onAddressFound }: Props, ref) => {

  const { onKeyUp: innerOnKeyUp, defaultValue, ...textFieldPropsRest } = textFieldProps;

  const {
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: "USA"
      }
    },
    debounce: 300,
  });

  const valueRef = React.useRef<string>((textFieldProps.value ?? textFieldProps.defaultValue ?? "") as string);

  let options: Array<{ title: string, terms: string[], fromApi: boolean }> = (data?.map(x => {
    return { title: x.description, terms: x.terms.map(x => x.value), fromApi: true };
  }) ?? []);

  //options = options.concat([{ title: valueRef.current, terms: [], fromApi: false }]);


  const handleKeyUp = (e: any) => {
    innerOnKeyUp?.(e);
    const v = e.target.value;
    setValue(v);
    valueRef.current = v;
  }

  return <Autocomplete
    ref={ref}
    freeSolo={true}
    value={valueRef.current}
    options={options}
    blurOnSelect={true}
    inputMode="search"
    includeInputInList={true}
    getOptionLabel={(option) => option?.title || valueRef.current}
    onChange={async (e, a) => {
      if (!a || typeof a === 'string') return;
      else {
        let found = false;
        let country, state, city, zipCode = undefined;
        try {
          const geocodeResult = await getGeocode({ address: a.title });
          if (geocodeResult && geocodeResult[0]) {
            found = true;
            const geo = geocodeResult[0];
            country = geo.address_components.find(x => x.types.find(x => x === 'country') != null)?.long_name;
            state = geo.address_components.find(x => x.types.find(x => x === 'administrative_area_level_1') != null)?.short_name;
            city = geo.address_components.find(x => x.types.find(x => x === 'locality') != null)?.long_name;
            zipCode = await getZipCode(geocodeResult[0], false);
            valueRef.current = a.title.split(",")[0];
            clearSuggestions();
          }
        }
        catch (e) {
          console.log(e);
        }
        if (found) {
          onAddressFound({ country, state, city, zipCode, terms: a.title.split(',') })
        }
      }
    }}
    renderInput={(params: AutocompleteRenderInputParams) => {
      return (
        <TextField
          {...textFieldPropsRest}
          onKeyUp={handleKeyUp}
          {...params}
        />
      )
    }}
  />
});