import * as React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { A8Button, Stepper } from 'av8-ui';
import { useEmbed } from '../shared/useEmbed';
import { useAuthData } from '../shared/authentication/AuthContext';
import { Wizard, WizardStepProps } from '../shared/components/Wizard';
import { AddressBar } from '../shared/components/AddressHeaderBar';
import { getRequestFlowParamsFromQuery } from './getRequestFlowParamsFromQuery';
import { DebugFlowState } from './components/DebugFlowState';
import { capitalize, Collapse, Fade, Slide } from '@material-ui/core';
import { useAppCommandListener } from '../shared/useAppCommandListener';
import { MobileScreenContainer } from './components/MobileScreenContainer';
import { Header } from './components/Header';
import { RequestFlowState } from './RequestFlowState';
import { NotAuthenticatedBar } from './components/NotAuthenticatedBar';
import { requestFlowSteps } from './requestFlowSteps';
import { CustomPrompt } from '../shared/components/CustomPrompt';
import { ExitDialogPrompt } from './components/ExitDialogPrompt';
import { useHeap } from '../shared/components/HeapIntegration';
import { ENABLE_CLIENT_DROPDOWN } from './flags';

const RequestFlowContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  & > *{
    width: 100%;
  }
`;

export type RequestFlowStepProps = WizardStepProps<RequestFlowState>;

export const RequestFlowControls = (props: WizardStepProps<RequestFlowState> & {
  nextButton?: React.ReactNode,
  backDisabled?: boolean,
  extractStateBeforeBack?: () => RequestFlowState
}) => {
  const backAllowed = props.pathCompleted > 0;
  const backDisabled = props.backDisabled === true;
  return (<>
    <div style={{ display: 'flex', minWidth: '100%' }}>
      <A8Button
        wide={true} style={{ opacity: backAllowed ? 1 : 0 }} disabled={!backAllowed || backDisabled}
        onClick={() => props.back({ state: props.extractStateBeforeBack?.() })}
        variant="outlined" data-meta-action="back">Back</A8Button>
      <div style={{ flex: 1, minWidth: 8 }}></div>
      {props.nextButton}
    </div>
    <br />
  </>)
}

export const RequestFlow = () => {
  const history = useHistory();
  const [saved, setSaved] = React.useState(false);
  const embed = useEmbed();
  const heap = useHeap();
  const authData = useAuthData();
  const {
    newClient, compsDetails, clientType, debug, entryStep = "setClient", existentClient } = getRequestFlowParamsFromQuery();

  const handleExit = () => {
    history.push("/account");
  };

  useAppCommandListener([{ command: "backPressed", handler: handleExit }]);

  return (<RequestFlowContainer>



    {authData == null ? (<NotAuthenticatedBar />) : (null)}

    <div style={{ flex: 1 }} data-meta-name="cma-request-flow">
      <Wizard
        wrapStep={(props, step) => {
          const lastStep = props.pathCompleted === props.pathTotal;
          const showAddressBar = props.pathCompleted >= 3 && !lastStep;
          const showExitButton = !lastStep;
          const showDoneButton = saved;
          const showStepper = !lastStep;

          let header = <Header
            showDoneButton={showDoneButton}
            showExitButton={showExitButton}
            onExitClick={handleExit}
            embed={embed}
          />

          return (<div data-meta-name={`step-${props.name}`}>
            {header}
            <Collapse in={showAddressBar}>
              <AddressBar>{`${props.state.compsDetails?.addressLine1}, ${props.state.compsDetails?.city}, ${props.state.compsDetails?.state}`}</AddressBar>
            </Collapse>
            <Collapse in={!showAddressBar}>
              <br />
            </Collapse>
            <Fade in={showStepper}>
              <MobileScreenContainer>
                <Collapse in={showStepper}>
                  <Stepper stepperSize={props.pathTotal + 1} activeStep={props.pathCompleted} style={{ padding: '0 1.5rem' }} />
                </Collapse>
              </MobileScreenContainer>
            </Fade>
            <Fade key={props.name} in={authData != null} enter={true} appear={true} mountOnEnter={true}>
              <MobileScreenContainer>
                <Slide direction={props.forward ? "left" : "right"} key={`step-${props.name}`} timeout={300} in={true} appear={true}>
                  <div style={{ padding: '1rem 1.5rem' }}>
                    {step}
                  </div>
                </Slide>
              </MobileScreenContainer>
            </Fade>
            {(debug === '1' || debug === 'true') && (<DebugFlowState {...props} />)}
          </div>);
        }}
        defaultShareGroup="global"
        steps={requestFlowSteps}
        entryStep={entryStep}
        onInitialized={() => {
          heap.track("StartedNewFlow", { entryStep });
        }}
        onStepChanged={({ to, forward }) => {
          heap.track(`${(forward ? "MovedToStep" : "ReturnedToStep")} ${capitalize(to.step)}`);
          window.scrollTo(0, 0);
        }}
        initialState={{
          newClient, clientType, compsDetails, existentClient, isNewClient: !ENABLE_CLIENT_DROPDOWN || newClient != null,
          onSaved: () => setSaved(true)
        }} />
    </div>

    {/* { !embed && <div style={{ paddingTop: '3rem' }}><SimpleFooter logoHref="/" /></div>} */}

    <CustomPrompt
      navigate={(location) => {
        history.push(location);
      }}
      when={!saved}
      component={ExitDialogPrompt}
    />

  </RequestFlowContainer>)
}
