import { EventEmitter } from "events";
import { useEffect } from "react"


(window as any).appEventEmitter = new EventEmitter();
(window as any).triggerAppEvent = (evt: string, argument: any) => {
  (window as any).appEventEmitter.emit(evt, argument);
}

interface IAppCommandHandler<ArgumentType> {
  command: string,
  handler: (argument: ArgumentType) => void;
}

export function useAppCommandListener(handlers: IAppCommandHandler<any>[]) {
  return useEffect(() => {
    const emitter: EventEmitter = (window as any).appEventEmitter;
    handlers.forEach(handler => {
      emitter.addListener(handler.command, handler.handler);
    });

    return () => {
      handlers.forEach(handler => {
        emitter.removeListener(handler.command, handler.handler);
      });
    }
  }, [handlers]);
}

