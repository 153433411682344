import { Fade } from "@material-ui/core";
import { A8Button, DesktopHeader, DesktopHeaderMenu, DesktopScreenTitle } from "av8-ui";
import { A8ButtonLink } from "../../shared/components/A8ButtonLink";
import { MobileHeader } from "../../shared/components/MobileHeader";

interface Props {
  showExitButton: boolean;
  showDoneButton: boolean;
  onExitClick: () => void;
  embed: boolean;
}

export const Header = (props: Props) => {
  const { showExitButton, showDoneButton, onExitClick, embed } = props;

  if (embed) {
    return (<MobileHeader
      leftButtons={(<Fade in={showExitButton}>
        <A8Button variant="text-white" disabled={!showExitButton} style={{ marginRight: 'auto' }} onClick={onExitClick}>Exit</A8Button>
      </Fade>)}
      rightButtons={(<Fade in={showDoneButton}>
        <A8Button variant="text-white" disabled={!showDoneButton} style={{ marginRight: 'auto' }} onClick={onExitClick}>Done</A8Button>
      </Fade>)}
      title="CMA Request"
    />);
  }
  else {
    return (<>
      <DesktopHeader
        logoAnchorProps={{ href: "/" }}
        rightMenu={(
          <DesktopHeaderMenu>
            <A8ButtonLink variant="text-white" to={"/account"}>Menu</A8ButtonLink>
            <A8ButtonLink variant="text-white" to={"/logout"}>Log Out</A8ButtonLink>
          </DesktopHeaderMenu>
        )} />
      <DesktopScreenTitle
        leftAction={(<Fade in={showExitButton}>
          <A8Button variant="text-white" disabled={!showExitButton} style={{ marginRight: 'auto' }} onClick={onExitClick}>Exit</A8Button>
        </Fade>)}
        rightAction={(<Fade in={showDoneButton}>
          <A8Button variant="text-white" disabled={!showDoneButton} style={{ marginRight: 'auto' }} href="/account">Done</A8Button>
        </Fade>)}
        title="CMA Request" />
    </>);
  }
}