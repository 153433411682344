/* tslint:disable */
/* eslint-disable */
/**
 * All APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreatePropertyDto
 */
export interface CreatePropertyDto {
    /**
     * 
     * @type {number}
     * @memberof CreatePropertyDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CreatePropertyDto
     */
    mlsId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePropertyDto
     */
    mlsSource?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePropertyDto
     */
    addressLine1: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePropertyDto
     */
    addressLine2?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePropertyDto
     */
    area?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePropertyDto
     */
    county?: string;
    /**
     * 
     * @type {number}
     * @memberof CreatePropertyDto
     */
    lotSizeSqFt?: number;
    /**
     * 
     * @type {number}
     * @memberof CreatePropertyDto
     */
    bathCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePropertyDto
     */
    hasAdditionalLivingSpace?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreatePropertyDto
     */
    additionalLivingSpaceSqFt?: number;
    /**
     * 
     * @type {number}
     * @memberof CreatePropertyDto
     */
    bedCount?: number;
    /**
     * 
     * @type {string}
     * @memberof CreatePropertyDto
     */
    buildingName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePropertyDto
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePropertyDto
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof CreatePropertyDto
     */
    garageSpaces?: number;
    /**
     * 
     * @type {number}
     * @memberof CreatePropertyDto
     */
    hoaFeeCents?: number;
    /**
     * 
     * @type {number}
     * @memberof CreatePropertyDto
     */
    currentPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof CreatePropertyDto
     */
    unit?: string;
    /**
     * 
     * @type {Date}
     * @memberof CreatePropertyDto
     */
    listDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof CreatePropertyDto
     */
    livingAreaSqFt?: number;
    /**
     * 
     * @type {string}
     * @memberof CreatePropertyDto
     */
    neighborhood: string;
    /**
     * 
     * @type {number}
     * @memberof CreatePropertyDto
     */
    nonGarageSpaces?: number;
    /**
     * 
     * @type {string}
     * @memberof CreatePropertyDto
     */
    style?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePropertyDto
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePropertyDto
     */
    renovationType?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePropertyDto
     */
    state: string;
    /**
     * 
     * @type {number}
     * @memberof CreatePropertyDto
     */
    yearBuilt?: number;
    /**
     * 
     * @type {string}
     * @memberof CreatePropertyDto
     */
    zipCode?: string;
}

export function CreatePropertyDtoFromJSON(json: any): CreatePropertyDto {
    return CreatePropertyDtoFromJSONTyped(json, false);
}

export function CreatePropertyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePropertyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'mlsId': !exists(json, 'mlsId') ? undefined : json['mlsId'],
        'mlsSource': !exists(json, 'mlsSource') ? undefined : json['mlsSource'],
        'addressLine1': json['addressLine1'],
        'addressLine2': !exists(json, 'addressLine2') ? undefined : json['addressLine2'],
        'area': !exists(json, 'area') ? undefined : json['area'],
        'county': !exists(json, 'county') ? undefined : json['county'],
        'lotSizeSqFt': !exists(json, 'lotSizeSqFt') ? undefined : json['lotSizeSqFt'],
        'bathCount': !exists(json, 'bathCount') ? undefined : json['bathCount'],
        'hasAdditionalLivingSpace': !exists(json, 'hasAdditionalLivingSpace') ? undefined : json['hasAdditionalLivingSpace'],
        'additionalLivingSpaceSqFt': !exists(json, 'additionalLivingSpaceSqFt') ? undefined : json['additionalLivingSpaceSqFt'],
        'bedCount': !exists(json, 'bedCount') ? undefined : json['bedCount'],
        'buildingName': !exists(json, 'buildingName') ? undefined : json['buildingName'],
        'city': json['city'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'garageSpaces': !exists(json, 'garageSpaces') ? undefined : json['garageSpaces'],
        'hoaFeeCents': !exists(json, 'hoaFeeCents') ? undefined : json['hoaFeeCents'],
        'currentPrice': !exists(json, 'currentPrice') ? undefined : json['currentPrice'],
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
        'listDate': !exists(json, 'listDate') ? undefined : (new Date(json['listDate'])),
        'livingAreaSqFt': !exists(json, 'livingAreaSqFt') ? undefined : json['livingAreaSqFt'],
        'neighborhood': json['neighborhood'],
        'nonGarageSpaces': !exists(json, 'nonGarageSpaces') ? undefined : json['nonGarageSpaces'],
        'style': !exists(json, 'style') ? undefined : json['style'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'renovationType': !exists(json, 'renovationType') ? undefined : json['renovationType'],
        'state': json['state'],
        'yearBuilt': !exists(json, 'yearBuilt') ? undefined : json['yearBuilt'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
    };
}

export function CreatePropertyDtoToJSON(value?: CreatePropertyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'mlsId': value.mlsId,
        'mlsSource': value.mlsSource,
        'addressLine1': value.addressLine1,
        'addressLine2': value.addressLine2,
        'area': value.area,
        'county': value.county,
        'lotSizeSqFt': value.lotSizeSqFt,
        'bathCount': value.bathCount,
        'hasAdditionalLivingSpace': value.hasAdditionalLivingSpace,
        'additionalLivingSpaceSqFt': value.additionalLivingSpaceSqFt,
        'bedCount': value.bedCount,
        'buildingName': value.buildingName,
        'city': value.city,
        'description': value.description,
        'garageSpaces': value.garageSpaces,
        'hoaFeeCents': value.hoaFeeCents,
        'currentPrice': value.currentPrice,
        'unit': value.unit,
        'listDate': value.listDate === undefined ? undefined : (value.listDate.toISOString()),
        'livingAreaSqFt': value.livingAreaSqFt,
        'neighborhood': value.neighborhood,
        'nonGarageSpaces': value.nonGarageSpaces,
        'style': value.style,
        'type': value.type,
        'renovationType': value.renovationType,
        'state': value.state,
        'yearBuilt': value.yearBuilt,
        'zipCode': value.zipCode,
    };
}


