/* tslint:disable */
/* eslint-disable */
/**
 * All APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SimpleClientResponseDto,
    SimpleClientResponseDtoFromJSON,
    SimpleClientResponseDtoFromJSONTyped,
    SimpleClientResponseDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface PaginatedSimpleClientResponseDto
 */
export interface PaginatedSimpleClientResponseDto {
    /**
     * 
     * @type {Array<SimpleClientResponseDto>}
     * @memberof PaginatedSimpleClientResponseDto
     */
    items: Array<SimpleClientResponseDto>;
    /**
     * 
     * @type {object}
     * @memberof PaginatedSimpleClientResponseDto
     */
    meta: object;
    /**
     * 
     * @type {object}
     * @memberof PaginatedSimpleClientResponseDto
     */
    links: object;
}

export function PaginatedSimpleClientResponseDtoFromJSON(json: any): PaginatedSimpleClientResponseDto {
    return PaginatedSimpleClientResponseDtoFromJSONTyped(json, false);
}

export function PaginatedSimpleClientResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedSimpleClientResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(SimpleClientResponseDtoFromJSON)),
        'meta': json['meta'],
        'links': json['links'],
    };
}

export function PaginatedSimpleClientResponseDtoToJSON(value?: PaginatedSimpleClientResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(SimpleClientResponseDtoToJSON)),
        'meta': value.meta,
        'links': value.links,
    };
}


