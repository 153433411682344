import { ButtonRange, ButtonRangeProps, InputContainer } from "av8-ui";
import { ControllerRenderProps, DeepMap, FieldError } from "react-hook-form";
import { getErrorMessage } from "./getErrorMessage";

export const ButtonRangeRenderer = ({
  buttonRangeProps, errors, errorMessage, label, notifyChange,
}: {
  notifyChange?: () => void,
  errorMessage?: string,
  errors?: DeepMap<Record<string, any>, FieldError>,
  buttonRangeProps: ButtonRangeProps,
  label?: string | React.ReactNode
}) => (props: ControllerRenderProps<Record<string, any>>) => {
  const error = errors ? getErrorMessage(props.name, errors) : undefined;
  return (
    <InputContainer label={label} padding="normal" error={error}>
      <ButtonRange initialValue={props.value} {...buttonRangeProps} onChange={(value?: string[]) => { props.onChange(value); notifyChange?.(); }} />
    </InputContainer>
  );
}