import * as React from 'react';

export function Checkmark() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path d="M18.4851 4.34303L7.17139 15.6567L1.51453 9.99988" stroke="#2A29A6" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
}