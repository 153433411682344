import React from 'react';
import styled from 'styled-components';
import { Typography, InputContainer } from "av8-ui";
import { CloseIcon } from '../../../shared/images/icons/CloseIcon';

interface Props {
    firstName: string;
    lastName: string;
    id?: number;
    email: string;
    onRemoveClient: (e: any) => void;
}

const SelectedClientContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid #2a29A6;
  border-radius: 8px;
  padding: 12px;
  background-color: #f0f0fe;
  position: relative;
`;

const CloseIconContainer = styled.div`
    position: absolute;
    top: 4px;
    right: 4px;
    width: 25px; height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    &:hover{
        background: rgba(0,0,0,.05);
    }
`;


export const SelectedClientCard = ({ firstName, lastName, email, onRemoveClient }: Props) => {
    return (
        <InputContainer padding="normal" label={'Selected Client'}>
            <SelectedClientContainer>
                <Typography variant="Body" color="primary">{`${firstName} ${lastName}`}</Typography>
                <Typography variant="Body" color="primary">{email}</Typography>
                <CloseIconContainer onClick={onRemoveClient}>
                    <CloseIcon />
                </CloseIconContainer>
            </SelectedClientContainer>
        </InputContainer>
    );
};