import React from "react";
import { RouteComponentProps } from "react-router";
import * as H from 'history';

interface CustomRoute {
  match: (location: H.Location) => boolean;
  forceRefresh?: boolean;
  render: (props: RouteComponentProps & { currentNode: React.ReactNode }) => React.ReactNode | null;
}

export function customRouter(props: {
  routeProps: RouteComponentProps,
  routes: Array<CustomRoute>;
  ref: React.MutableRefObject<{ rendered: React.ReactNode }>;
}): React.ReactNode {

  const { routeProps: { location }, routes, ref } = props;

  const ctx = { ...props.routeProps, currentNode: ref.current?.rendered };

  for (let i = 0; i < routes.length; i++) {
    const route = routes[i];
    if (route.match(location)) {
      const node = route.render(ctx);
      if (route.forceRefresh === true && ref.current) {
        if (ref.current != null) {
          window.location.reload();
        }
        const formerNode = ref.current?.rendered;
        ref.current = { rendered: node };
        return formerNode || ref.current.rendered;
      }
      else {
        ref.current = { rendered: node };
        return ref.current.rendered;
      }
    }
  }
  return null;
}