declare const webkit: any;

export const AppActions = {
  backToHome: () => {
    try {
      webkit.messageHandlers.exit.postMessage({});
    } catch (err) {
      console.log("error signalling native app");
      console.log(err.toString());
    }
  },
};
