/* tslint:disable */
/* eslint-disable */
/**
 * All APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateCMARequestDto,
    CreateCMARequestDtoFromJSON,
    CreateCMARequestDtoToJSON,
    CreateCMAResponseDto,
    CreateCMAResponseDtoFromJSON,
    CreateCMAResponseDtoToJSON,
} from '../models';

export interface RequestControllerCreateCMARequest {
    createCMARequestDto: CreateCMARequestDto;
}

export interface RequestControllerGetRequestHistoryRequest {
    agentId: string;
    query: string;
    page: string;
    limit: string;
}

/**
 * 
 */
export class RequestsControllerApi extends runtime.BaseAPI {

    /**
     */
    async requestControllerCreateCMARaw(requestParameters: RequestControllerCreateCMARequest): Promise<runtime.ApiResponse<CreateCMAResponseDto>> {
        if (requestParameters.createCMARequestDto === null || requestParameters.createCMARequestDto === undefined) {
            throw new runtime.RequiredError('createCMARequestDto','Required parameter requestParameters.createCMARequestDto was null or undefined when calling requestControllerCreateCMA.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/request/cma/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCMARequestDtoToJSON(requestParameters.createCMARequestDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCMAResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async requestControllerCreateCMA(requestParameters: RequestControllerCreateCMARequest): Promise<CreateCMAResponseDto> {
        const response = await this.requestControllerCreateCMARaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async requestControllerGetRequestHistoryRaw(requestParameters: RequestControllerGetRequestHistoryRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agentId === null || requestParameters.agentId === undefined) {
            throw new runtime.RequiredError('agentId','Required parameter requestParameters.agentId was null or undefined when calling requestControllerGetRequestHistory.');
        }

        if (requestParameters.query === null || requestParameters.query === undefined) {
            throw new runtime.RequiredError('query','Required parameter requestParameters.query was null or undefined when calling requestControllerGetRequestHistory.');
        }

        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling requestControllerGetRequestHistory.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling requestControllerGetRequestHistory.');
        }

        const queryParameters: any = {};

        if (requestParameters.agentId !== undefined) {
            queryParameters['agentId'] = requestParameters.agentId;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/request/history`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async requestControllerGetRequestHistory(requestParameters: RequestControllerGetRequestHistoryRequest): Promise<void> {
        await this.requestControllerGetRequestHistoryRaw(requestParameters);
    }

}
