/* tslint:disable */
/* eslint-disable */
/**
 * All APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    LoginDTO,
    LoginDTOFromJSON,
    LoginDTOToJSON,
    SignUpDto,
    SignUpDtoFromJSON,
    SignUpDtoToJSON,
} from '../models';

export interface AuthControllerLoginRequest {
    loginDTO: LoginDTO;
}

export interface AuthControllerRefreshRequest {
    body: string;
}

export interface AuthControllerRenderUpdatePasswordFormRequest {
    token: string;
}

export interface AuthControllerResetPasswordRequest {
    body: string;
}

export interface AuthControllerResetPasswordRequestsRequest {
    body: object;
}

export interface AuthControllerSignUpRequest {
    signUpDto: SignUpDto;
}

export interface AuthControllerUpdatePasswordRequest {
    body: string;
}

/**
 * 
 */
export class AuthControllerApi extends runtime.BaseAPI {

    /**
     */
    async authControllerLoginRaw(requestParameters: AuthControllerLoginRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.loginDTO === null || requestParameters.loginDTO === undefined) {
            throw new runtime.RequiredError('loginDTO','Required parameter requestParameters.loginDTO was null or undefined when calling authControllerLogin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginDTOToJSON(requestParameters.loginDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async authControllerLogin(requestParameters: AuthControllerLoginRequest): Promise<void> {
        await this.authControllerLoginRaw(requestParameters);
    }

    /**
     */
    async authControllerRefreshRaw(requestParameters: AuthControllerRefreshRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling authControllerRefresh.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/refresh`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async authControllerRefresh(requestParameters: AuthControllerRefreshRequest): Promise<void> {
        await this.authControllerRefreshRaw(requestParameters);
    }

    /**
     */
    async authControllerRenderUpdatePasswordFormRaw(requestParameters: AuthControllerRenderUpdatePasswordFormRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling authControllerRenderUpdatePasswordForm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/update-password/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async authControllerRenderUpdatePasswordForm(requestParameters: AuthControllerRenderUpdatePasswordFormRequest): Promise<void> {
        await this.authControllerRenderUpdatePasswordFormRaw(requestParameters);
    }

    /**
     */
    async authControllerResetPasswordRaw(requestParameters: AuthControllerResetPasswordRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling authControllerResetPassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/reset-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async authControllerResetPassword(requestParameters: AuthControllerResetPasswordRequest): Promise<void> {
        await this.authControllerResetPasswordRaw(requestParameters);
    }

    /**
     */
    async authControllerResetPasswordRequestsRaw(requestParameters: AuthControllerResetPasswordRequestsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling authControllerResetPasswordRequests.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/reset-password-requests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async authControllerResetPasswordRequests(requestParameters: AuthControllerResetPasswordRequestsRequest): Promise<void> {
        await this.authControllerResetPasswordRequestsRaw(requestParameters);
    }

    /**
     */
    async authControllerSignUpRaw(requestParameters: AuthControllerSignUpRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.signUpDto === null || requestParameters.signUpDto === undefined) {
            throw new runtime.RequiredError('signUpDto','Required parameter requestParameters.signUpDto was null or undefined when calling authControllerSignUp.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/sign-up`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignUpDtoToJSON(requestParameters.signUpDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async authControllerSignUp(requestParameters: AuthControllerSignUpRequest): Promise<void> {
        await this.authControllerSignUpRaw(requestParameters);
    }

    /**
     */
    async authControllerUpdatePasswordRaw(requestParameters: AuthControllerUpdatePasswordRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling authControllerUpdatePassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/update-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async authControllerUpdatePassword(requestParameters: AuthControllerUpdatePasswordRequest): Promise<void> {
        await this.authControllerUpdatePasswordRaw(requestParameters);
    }

}
