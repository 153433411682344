import { Divider } from "@material-ui/core";
import { A8Button } from "av8-ui";
import { useForm } from "react-hook-form";
import { RequestFlowControls, RequestFlowStepProps } from "../RequestFlow";
import { StepTitle } from "./StepTitle";
import styled from 'styled-components';
import { propertyTypeOptions } from "./SetCompsDetailsStep/SetCompsDetailsStep";

type FormData = {

}

const Paragraph = styled.p`
  margin: 1.5rem 0;
`;

export const ConfirmCompsDetailsStep = (props: RequestFlowStepProps) => {

  const { handleSubmit, /*register, errors*/ } = useForm();

  const onSubmit = (data: FormData) => {
    props.moveTo("setAdditionalLivingSpace", { ...props.state, compsDetailConfirmed: true });
  };

  const submitHandler = handleSubmit(onSubmit);

  const flowState = props.lastSentState ?? props.state;
  const {
    addressLine1, addressLine2, city, state, zip,
    propertyType, livingSpace, baths, beds, multiFamilyUnits

  } = flowState.compsDetails ?? {};

  const controls = <RequestFlowControls {...props} nextButton={<A8Button wide={true} variant="contained" type="submit" data-meta-action="next">Next</A8Button>} />;

  const getPropertyTypeLabel = (propertyType: string) => propertyTypeOptions.find(t => t.value === propertyType)?.label;

  const normalizePlural = (singular: string, plural: string, amount?: number) => Number(amount) >= 2 ? plural : singular;

  return (
    <form onSubmit={submitHandler} noValidate data-meta-name="step-3">
      {controls}
      <StepTitle primaryText="*We’ve got it.* Confirm the details below." />
      <Paragraph>{addressLine1} {addressLine2}</Paragraph>
      <Paragraph>{city}, {state} {zip}</Paragraph>
      <Divider />
      <Paragraph>{getPropertyTypeLabel(propertyType as string)} {multiFamilyUnits ? `(${multiFamilyUnits} units)` : ""} </Paragraph>
      <Paragraph>{beds} {normalizePlural('Bedroom', 'Bedrooms', beds)}</Paragraph>
      <Paragraph>{baths} {normalizePlural('Bath', 'Baths', baths)}</Paragraph>
      <Paragraph>{new Intl.NumberFormat('en').format(livingSpace as number)} Sq Ft</Paragraph>
      <Paragraph><A8Button variant="outlined" onClick={() => props.back()} data-meta-action="edit-details">Edit  Details</A8Button></Paragraph>
      <br />
      {controls}    
      </form>
  );
}