import * as React from 'react';
import { A8Button, A8ButtonProps } from 'av8-ui';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

export function A8ButtonLink(props: A8ButtonProps & { to: string }) {
  const { to, ...rest } = props;
  const renderLink = React.useMemo(
    () =>
      React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to],
  );

  return (
    <A8Button {...rest as any} component={renderLink} />
  );
}