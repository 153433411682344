import { captureException } from "../shared/captureException";
import '../shared/authentication/sbl-auth-interfaces';
import { authService } from "../shared/authentication/AuthContext";

export const DesktopActions = {
  logout: () => {
    try {
      authService.signOut();
    } catch (error) {
      captureException(error)
    }
  },
};
