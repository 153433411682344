import { usaStates } from "../shared/components/StateTextField";
import { getQueryObject } from "../shared/getQueryObject";
import { RequestFlowState } from "./RequestFlowState";

export const getRequestFlowParamsFromQuery = () => {
  const {
    clientFirstname,
    clientLastname,
    clientEmail,
    clientId,
    clientType: clientTypeQ,
    entryStep,
    listingAddressLine1,
    listingAddressLine2,
    listingCity,
    listingBeds,
    listingBaths,
    listingLivingSpace,
    listingState: listingStateQ,
    listingZip,
    debug,
  } = getQueryObject(window.location.search);
  const newClient: RequestFlowState["newClient"] =
    clientId == null && (clientFirstname || clientLastname || clientEmail)
      ? {
          firstname: clientFirstname,
          lastname: clientLastname,
          email: clientEmail,
        }
      : undefined;
  const existentClient: RequestFlowState["existentClient"] =
    clientId != null
      ? {
          id: parseInt(clientId, 10),
          firstname: clientFirstname,
          lastname: clientLastname,
          email: clientEmail,
        }
      : undefined;
  const compsDetails: RequestFlowState["compsDetails"] = {
    addressLine1: listingAddressLine1,
    addressLine2: listingAddressLine2,
    city: listingCity,
    state: usaStates.indexOf(listingStateQ) !== -1 ? listingStateQ : undefined,
    zip: listingZip?.replace(/[^a-z0-9]/gi, ""),
    beds: listingBeds != null ? parseInt(listingBeds) : undefined,
    baths: listingBaths != null ? parseFloat(listingBaths) : undefined,
    livingSpace:
      listingLivingSpace != null ? parseInt(listingLivingSpace) : undefined,
  };
  let clientType: "seller" | "buyer" | undefined;
  if (clientTypeQ === "seller" || clientTypeQ === "buyer")
    clientType = clientTypeQ;

  return {
    newClient,
    existentClient,
    compsDetails,
    clientId,
    clientType,
    debug,
    entryStep,
  };
};
