import styled from "styled-components";
import { A8Button, InputContainer, Typography } from "av8-ui";
import { Controller, useForm } from "react-hook-form";
import { RequestFlowControls, RequestFlowStepProps } from "../RequestFlow";
import { ButtonRangeRenderer } from './../components/buttonRangeRender';
import { NumberFormatCustom } from '../../shared/components/NumberFormat';
import { StepTitle } from "./StepTitle";
import { Divider, InputAdornment, OutlinedInput } from "@material-ui/core";
import { getErrorMessageFromField } from "./getErrorMessage";
import { cTInt } from "./converters";
import { validRange, notEmptyArray, requiredDelayed } from "./reactHookFormValidations";
import { RequiredSymbol } from "./RequiredSymbol";
import { RequestFlowState } from "../RequestFlowState";

interface FormData {
  baths?: string[];
  beds?: string[];
  livingSpaceMax?: string;
  livingSpaceMin?: string;
  priceMax?: string;
  priceMin?: string;
}

const DividerWithSpace = styled(Divider)`
  margin: 1rem 0 0.5rem 0;
`;

type StateFragment = RequestFlowState["compsPreferences"];

function stateToFormData(data: StateFragment): FormData {
  const {
    bathsMax,
    bathsMin,
    bedsMax,
    bedsMin,
    livingSpaceMax,
    livingSpaceMin,
    priceMax,
    priceMin,
  } = data ?? {};
  return {
    baths: valuesToRange(bathsMin?.toString() ?? "", bathsMax?.toString() ?? "") ?? [],
    beds: valuesToRange(bedsMin?.toString() ?? "", bedsMax?.toString() ?? "") ?? [],
    livingSpaceMax: livingSpaceMax?.toString() ?? "",
    livingSpaceMin: livingSpaceMin?.toString() ?? "",
    priceMax: priceMax?.toString() ?? "",
    priceMin: priceMin?.toString() ?? "",
  }
}

function formDataToState(data: FormData): StateFragment {
  const {
    baths: [bathsMin, bathsMax] = [],
    beds: [bedsMin, bedsMax] = [],
    livingSpaceMax,
    livingSpaceMin,
    priceMax,
    priceMin,
  } = data;
  return {
    bathsMax: bathsMax,
    bathsMin: bathsMin,
    bedsMax: bedsMax,
    bedsMin: bedsMin,
    livingSpaceMax: cTInt(livingSpaceMax),
    livingSpaceMin: cTInt(livingSpaceMin),
    priceMax: cTInt(priceMax),
    priceMin: cTInt(priceMin),
  }
}

function valuesToRange(first: string | undefined, second: string | undefined): (string)[] | undefined {
  if (first == null || first === "") return undefined;
  if (second == null || second === "") return [first, first];
  return [first, second];
}

const TextRangeContainer = styled.div`
  display: flex;
  & > *{
    flex: 1;
  }
  & > .textRangeSplitter{
    flex: 0;
    padding: 0.5rem;
  }
  align-items: center;
  justify-content: center;
`;

export const SetCompsPreferencesStep = (props: RequestFlowStepProps) => {

  const flowState = props.lastSentState ?? props.state;
  const compsPreferences = flowState.compsPreferences ?? {};
  const defaultValues: FormData = stateToFormData(compsPreferences);

  const { handleSubmit, errors, control, getValues, register } = useForm({ defaultValues });

  const getModifiedFlowState = (data: FormData) => {
    return { ...props.state, compsPreferences: formDataToState(data) };
  }

  const onSubmit = (data: FormData) => {
    props.moveTo("setExtraInformation", getModifiedFlowState(data));
  };

  const submitHandler = handleSubmit(onSubmit);

  const controls = <RequestFlowControls {...props} extractStateBeforeBack={() => getModifiedFlowState(getValues())} nextButton={<A8Button wide={true} variant="contained" type="submit" data-meta-action="next">Next</A8Button>} />;


  return (<form onSubmit={submitHandler} noValidate data-meta-name="step-6">
    {controls}
    <StepTitle primaryText="*Tell us how* you would like us to run your comps." />

    <Typography padding="normal" display="block" variant="Caption" color="grey">
      What is the sales price range we should search against? <RequiredSymbol />
    </Typography>
    <InputContainer
      error={getErrorMessageFromField(errors.priceMin || errors.priceMax)}
    >
      <TextRangeContainer>
        <OutlinedInput error={!!errors.priceMin}
          name="priceMin"
          placeholder="Min"
          inputRef={register({ validate: requiredDelayed(getValues)("priceMin") })}
          defaultValue={defaultValues?.priceMin ?? ""}
          fullWidth={true} margin="dense"
          inputComponent={NumberFormatCustom as any} startAdornment={<InputAdornment position="start">$</InputAdornment>} />

        <div className="textRangeSplitter"> - </div>

        <OutlinedInput
          placeholder="Max" error={!!errors.priceMax}
          name="priceMax" inputRef={register({ validate: { ...validRange(getValues)("priceMin", "priceMax"), ...requiredDelayed(getValues)("priceMax") } })}
          defaultValue={defaultValues?.priceMax ?? ""}
          fullWidth={true} margin="dense"
          inputComponent={NumberFormatCustom as any} startAdornment={<InputAdornment position="start">$</InputAdornment>} />
      </TextRangeContainer>
    </InputContainer>
    <Typography padding="normal" display="block" variant="Caption" color="grey">i.e. $500,000 — $1,750,000 range</Typography>

    <DividerWithSpace />

    <Controller name="beds" defaultValue={defaultValues.beds} rules={{ required: true, validate: notEmptyArray(getValues)("beds") }} control={control}
      render={ButtonRangeRenderer({
        errors, label: <span>How many bedrooms should the comps have? Select two for a range <RequiredSymbol /></span>, buttonRangeProps: {
          options: [
            { label: 'Studio', value: '0' },
            ...['1', '2', '3', '4', '5+'].map(x => ({ label: x, value: x }))
          ]
        }
      })} />

    <DividerWithSpace />

    <Controller name="baths" defaultValue={defaultValues.baths} rules={{ required: true, validate: notEmptyArray(getValues)("baths") }} control={control}
      render={ButtonRangeRenderer({
        errors, label: (<span>How many bathrooms should the comps have? Select two for a range <RequiredSymbol /></span>), buttonRangeProps: {
          options:
            ['0.5', '1', '1.5', '2', '2.5', '3', '3.5', '4+'].map(x => ({ label: x, value: x })),
        }
      })} />

    <DividerWithSpace />

    <Typography padding="normal" display="block" variant="Caption" color="grey">What living space should the comps have? <RequiredSymbol /></Typography>

    <InputContainer
      error={getErrorMessageFromField(errors.livingSpaceMin || errors.livingSpaceMax)}
    >
      <TextRangeContainer>
        <OutlinedInput error={!!errors.livingSpaceMin}
          name="livingSpaceMin"
          placeholder="Min"
          inputRef={register({ validate: requiredDelayed(getValues)("livingSpaceMin") })}
          defaultValue={defaultValues?.livingSpaceMin ?? ""}
          fullWidth={true} margin="dense"
          inputComponent={NumberFormatCustom as any} endAdornment={<InputAdornment position="end">sq.ft</InputAdornment>} />

        <div className="textRangeSplitter"> - </div>

        <OutlinedInput
          placeholder="Max" error={!!errors.livingSpaceMax}
          name="livingSpaceMax" inputRef={register({ validate: { ...validRange(getValues)("livingSpaceMin", "livingSpaceMax"), ...requiredDelayed(getValues)("livingSpaceMax") } })}
          defaultValue={defaultValues?.livingSpaceMax ?? ""}
          fullWidth={true} margin="dense"
          inputComponent={NumberFormatCustom as any} endAdornment={<InputAdornment position="end">sq.ft</InputAdornment>} />
      </TextRangeContainer>
    </InputContainer>

    <Typography padding="normal" display="block" variant="Caption" color="grey">i.e. 100 sq ft — 25,000 sq ft range</Typography>
    <br />
    {controls}
  </form>);
}