/* tslint:disable */
/* eslint-disable */
/**
 * All APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientDto
 */
export interface ClientDto {
    /**
     * 
     * @type {number}
     * @memberof ClientDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ClientDto
     */
    agentId: number;
    /**
     * 
     * @type {string}
     * @memberof ClientDto
     */
    seriousnessOption: string;
    /**
     * 
     * @type {string}
     * @memberof ClientDto
     */
    meetOption: string;
    /**
     * 
     * @type {string}
     * @memberof ClientDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ClientDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof ClientDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ClientDto
     */
    firstAddressLine: string;
    /**
     * 
     * @type {string}
     * @memberof ClientDto
     */
    secondAddressLine: string;
    /**
     * 
     * @type {string}
     * @memberof ClientDto
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof ClientDto
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof ClientDto
     */
    zip: string;
    /**
     * 
     * @type {string}
     * @memberof ClientDto
     */
    googleId: string;
    /**
     * 
     * @type {boolean}
     * @memberof ClientDto
     */
    isPlaceholder: boolean;
}

export function ClientDtoFromJSON(json: any): ClientDto {
    return ClientDtoFromJSONTyped(json, false);
}

export function ClientDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'agentId': json['agentId'],
        'seriousnessOption': json['seriousnessOption'],
        'meetOption': json['meetOption'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'firstAddressLine': json['firstAddressLine'],
        'secondAddressLine': json['secondAddressLine'],
        'city': json['city'],
        'state': json['state'],
        'zip': json['zip'],
        'googleId': json['googleId'],
        'isPlaceholder': json['isPlaceholder'],
    };
}

export function ClientDtoToJSON(value?: ClientDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'agentId': value.agentId,
        'seriousnessOption': value.seriousnessOption,
        'meetOption': value.meetOption,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'firstAddressLine': value.firstAddressLine,
        'secondAddressLine': value.secondAddressLine,
        'city': value.city,
        'state': value.state,
        'zip': value.zip,
        'googleId': value.googleId,
        'isPlaceholder': value.isPlaceholder,
    };
}


