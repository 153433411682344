import * as React from 'react';
import { ClientControllerSimpleListByAgentRequest, PaginatedSimpleClientResponseDto } from '../../../shared/apis/app/generated';
import { authService } from '../../../shared/authentication/AuthContext';
import { getClientsApi } from '../../apiFactory';
import { Client } from './ClientsTextField';
import { captureException } from '../../../shared/captureException';

const clientLimit = 9999;

const getClients = async () => {
  const { agentId = "0" } = authService.getCredentials() || {};
  const api = getClientsApi();

  if (api == null) {
    console.log("Load error Api Null", {});
  }
  else {
    return api.clientControllerSimpleListByAgent({
      agentId: Number(agentId),
      page: 1,
      limit: clientLimit,
     } as ClientControllerSimpleListByAgentRequest );
  }
}

export function useAgentClients(){
  const [clientList, setClientList] = React.useState<Client[]>([]);
  const [loadingClients, setLoadingClients]  = React.useState(true);
  const loadClients = React.useMemo(() => {
    return (async () => {
      try {
        const clients = await getClients() as PaginatedSimpleClientResponseDto;
        setClientList(clients.items as Client[]);
      }
      catch (e) {
        captureException(e);
      }
      finally{
        setLoadingClients(false);
      }
    });
  }, []);

  React.useEffect(() => {
    loadClients();
  }, [loadClients]);

  return {
    clientList,
    loadingClients,
  }
}
