import { CustomPromptComponentProps } from "../../shared/components/CustomPrompt";
import { ExitDialog } from "./ExitDialog";

/* This is like an adapter */

export const ExitDialogPrompt = ({ open, onConfirm, onCancel }: CustomPromptComponentProps) => {
  return (<ExitDialog
    onCancel={onCancel}
    onConfirm={onConfirm}
    open={open}
  />)
}