import * as React from 'react';
import { CircularProgress, Divider, Fade } from "@material-ui/core";
import { A8Button } from "av8-ui";
import { RequestFlowStepProps } from "../RequestFlow";
import { RequestControllerCreateCMARequest } from '../../shared/apis/app/generated';
import { StepTitle } from './StepTitle';
import { getApi } from '../apiFactory';
import styled from 'styled-components';
import { RequestFlowState } from '../RequestFlowState';
import { useHeap } from '../../shared/components/HeapIntegration';
import { captureException } from '../../shared/captureException';
import { authService } from '../../shared/authentication/AuthContext';

export const sendRequest = async (data: RequestFlowState) => {
  const { agentId = "0" } = authService.getCredentials() ?? {};
  const {
    newClient,
    existentClient,
    compsDetails: {
      addressLine1 = "",
      addressLine2,
      baths,
      beds,
      city = "",
      livingSpace,
      state = "",
      propertyType,
      multiFamilyUnits,
      zip: zipCode,
    } = {},
    hasAdditionalLivingSpace,
    estimatedAdditionalLivingSpace
  } = data;

  const client = {
    ...existentClient,
    ...newClient
  }

  const { priceMin, priceMax } = data.compsPreferences || {};

  const payload: RequestControllerCreateCMARequest = {
    createCMARequestDto: {
      agentId,
      estimatedPriceMin: priceMin,
      estimatedPriceMax: priceMax,
      client: {
        id: client.id,
        email: (client.email?.trim() || undefined) as any,
        firstName: client.firstname,
        lastName: client.lastname,
        type: data.clientType,
      },
      subjectPropertyMlsId: "",
      subjectProperty: {
        addressLine1,
        addressLine2,
        bathCount: baths,
        bedCount: beds,
        city,
        livingAreaSqFt: livingSpace,
        neighborhood: "",
        state,
        type: propertyType,
        zipCode,
        hasAdditionalLivingSpace: hasAdditionalLivingSpace == null ? undefined : hasAdditionalLivingSpace,
        additionalLivingSpaceSqFt: estimatedAdditionalLivingSpace,
        renovationType: data.propertyRenovatedState,
        unit: multiFamilyUnits
      },
      compsPreferences: {
        ...data.compsPreferences,
        extraInformation: data.extraInformation,
      }
    }
  };

  const api = getApi();
  if (api == null) {
    console.log("Fake Submit", payload);
    return new Promise(r => setTimeout(r, 3000));
  }
  else {
    return api.requestControllerCreateCMA(payload);
  }
}

const DividerWithSpace = styled(Divider)`
  margin: 2rem 0;
  background-color: #2A29A655;
`;

export const SendRequestStep = (props: RequestFlowStepProps) => {
  // const api: RequestsControllerApi = new RequestsControllerApi();
  const flowState = props.state;
  const [sendState, setSendState] = React.useState<'sending' | 'error' | 'sent'>('sending');
  const { addressLine1, addressLine2, city, state, zip } = flowState.compsDetails ?? {};
  const heap = useHeap();

  const send = React.useMemo(() => {
    return (async () => {
      try {
        setSendState('sending');
        await sendRequest(flowState);
        setSendState('sent');
        heap.track("RequestSent");
        flowState.onSaved();
      }
      catch (e) {
        captureException(e);
        setSendState('error');
        heap.track("FailedToSendRequest");
      }
    });
  }, [flowState, heap])

  React.useEffect(() => {
    send();
  }, [send]);

  const header = null; //(<RequestFlowControls {...props} backDisabled={sendState === 'sending' || sendState === 'sent'} />);
  const footer = (<><DividerWithSpace />
    <p>{addressLine1 ?? "address"} {addressLine2}</p>
    <p>{city ?? "city"}, {state ?? "state"} {zip ?? "zip"}</p>
  </>);

  if (sendState === 'sending') {
    return (<div>
      {header}
      <StepTitle primaryText="*Sending* your request" />
      <div style={{ margin: '2rem 0' }}>
        <CircularProgress />
      </div>
      {footer}
    </div>);
  }

  if (sendState === 'error') {
    return (<Fade key="error" in={true} enter={true}><div>
      {header}
      <div><StepTitle primaryText="*Request not sent.* Something went wrong." />
        <A8Button variant="contained" onClick={() => { send(); heap.track("RetryClicked"); }}>Try Again</A8Button>
      </div><br />
      {footer}
    </div></Fade>);
  }

  return (<Fade key="done" in={true} enter={true}><div>
    {header}
    <StepTitle primaryText={`*Request received!*
We will email you with updates.`} />
    <p style={{ fontWeight: 500 }}>You’ll receive your comps to review within 24 hours</p>
    {footer}
  </div></Fade>);
}
