import * as React from 'react';

export interface WizardStepProps<State> {
  back: (options?: { state?: State }) => void;
  lastSentState?: State;
  pathTotal: number;
  moveTo: (key: string, state: State) => void;
  pathCompleted: number;
  state: State;
  name: string;
  forward: boolean;
  allSteps: { [key: string]: WizardStepExtended<State> };
}

export interface WizardStep<State> {
  component?: (props: WizardStepProps<State>) => any;
  movesTo?: string[];
  renderStep?: (props: WizardStepProps<State>) => React.ReactNode;
  shareGroup?: string;
}

interface WizardStepExtended<State> {
  component?: (props: WizardStepProps<State>) => any;
  movesTo: string[];
  name: string;
  parents: { [key: string]: true };
  pathWeight: number;
  renderStep?: (props: WizardStepProps<State>) => React.ReactNode;
  shareGroup?: string;
}

export interface WizardProps<State> {
  defaultShareGroup?: string;
  entryStep: string;
  initialState: State;
  renderAfter?: (props: WizardStepProps<State>) => React.ReactNode;
  renderBefore?: (props: WizardStepProps<State>) => React.ReactNode;
  steps: { [key: string]: WizardStep<State> };
  wrapStep?: (props: WizardStepProps<State>, step: React.ReactNode) => React.ReactNode;
  onStepChanged?: (data: { forward: boolean, from?: { state: State, step: string }, to: { state: State, step: string } }) => void,
  onInitialized?: () => void;
}

/**
 * @author Juliano Appel Klein
 */
export const Wizard = function <State>({
  defaultShareGroup,
  entryStep: entryStepKey,
  initialState,
  renderAfter,
  renderBefore,
  steps,
  wrapStep,
  onStepChanged,
  onInitialized,
}: WizardProps<State>) {

  type WizardMemo = Array<{ state: State, lastSentState?: State, step: string }>;

  const { enhancedSteps, maxWeight } = React.useMemo(() => {
    function enhanceSteps() {
      const stepsDict: { [key: string]: WizardStepExtended<State> } = {};

      //normalize
      Object.keys(steps).forEach((name) => {
        const step = steps[name];
        const { component, movesTo = [], renderStep, shareGroup = defaultShareGroup } = step;
        const stepExtended: WizardStepExtended<State> = {
          component,
          movesTo,
          name,
          parents: {},
          pathWeight: 0,
          renderStep,
          shareGroup
        };
        stepsDict[name] = stepExtended;
      });

      //validate and map parents
      Object.keys(stepsDict).forEach((name) => {
        const step = stepsDict[name];

        for (let j = 0; j < step.movesTo.length; j++) {
          let _continue = step.movesTo[j];
          let child = stepsDict[_continue];
          if (name === _continue)
            throw new Error(`A step can't reference itself.`);
          if (child == null) {
            throw new Error(`Broken continue. Check ${name} => ${_continue}.`);
          }
          if (child.parents[step.name]) {
            throw new Error(
              `Duplicated reference found. A step cannot link to another step more than once.
    Reasons: Duplicated links or circular reference. Check ${name} => ${_continue}.`
            );
          }
          child.parents[step.name] = true;
        }
      });

      //console.log(_stepDict);

      function computePathWeight(
        accWeight: number,
        step: WizardStepExtended<State>
      ): number {
        let weight = 0;

        for (let i = 0; i < step.movesTo.length; i++) {
          let child = stepsDict[step.movesTo[i]];
          let childWeight = computePathWeight(accWeight, child);
          weight = Math.max(weight, childWeight);
        }

        step.pathWeight = weight;
        return weight + 1;
      }

      let entryStep = stepsDict[entryStepKey];
      if (!entryStep) throw new Error('Could not find entry step.');
      if (Object.keys(entryStep.parents).length) {
        console.warn("Entry step has parents.");
      }
      computePathWeight(0, entryStep);

      for (let stepName in stepsDict) {
        let stepExtend = stepsDict[stepName];
        if (stepExtend.pathWeight == null)
          console.warn(
            `The step ${stepName}  does not have connections with your entry point.`
          );
      }
      return stepsDict
    };
    let maxWeight = 0;
    const enhancedSteps = enhanceSteps();
    Object.keys(enhancedSteps).forEach(x => maxWeight = Math.max(maxWeight, enhancedSteps[x].pathWeight));
    return {
      enhancedSteps,
      maxWeight
    };
  }, [steps, entryStepKey, defaultShareGroup]);

  React.useEffect(()=>{
    onInitialized?.();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const forward = React.useRef<boolean>(true);
  const [wizardMemo, setWizardMemo] = React.useState<WizardMemo>([{ state: initialState, step: entryStepKey }]);
  const memo = wizardMemo.slice(-1)[0];
  const currentStep = enhancedSteps[memo.step];

  function shareStateWithCurrentStepGroup(memo: WizardMemo, state: State) {
    if (currentStep.shareGroup) {
      for (let i = wizardMemo.length - 1; i >= 0; i--) {
        const ancestorStepMemo = wizardMemo[i];
        const ancestorStep = enhancedSteps[ancestorStepMemo.step];
        if (currentStep.shareGroup === ancestorStep.shareGroup) {
          memo[i] = { ...ancestorStepMemo, state, lastSentState: state };
        }
        else break;
      }
    }
  }

  const back = (opts: { state?: State } = {}) => {
    if (wizardMemo.length <= 1) return;
    const from = wizardMemo.slice(-1)[0];
    forward.current = false;
    if (opts.state != null) {
      shareStateWithCurrentStepGroup(wizardMemo, opts.state);
    }
    const slicedMemo = wizardMemo.slice(0, wizardMemo.length - 1);
    setWizardMemo(slicedMemo);
    const to = slicedMemo.slice(-1)[0];
    onStepChanged?.({ from, to, forward: false });
  }

  const moveTo = (key: string, state: any) => {
    const from = wizardMemo.length > 0 ? wizardMemo.slice(-1)[0] : undefined;
    const step = enhancedSteps[key];
    if (!step || currentStep.movesTo.indexOf(key) === -1) throw new Error('Invalid step.');
    forward.current = true;
    let updatedMemo = [
      ...wizardMemo.slice(0, wizardMemo.length - 1),
      { ...memo, lastSentState: state },
      { state, step: key }
    ];
    shareStateWithCurrentStepGroup(updatedMemo, state);
    setWizardMemo(updatedMemo);
    const to = updatedMemo.slice(-1)[0];
    onStepChanged?.({ from, to, forward: true });
  }

  const { component: Component, renderStep, name } = currentStep;
  const props = {
    back,
    moveTo,
    name,
    state: memo.state,
    lastSentState: memo.lastSentState,
    pathCompleted: maxWeight - currentStep.pathWeight,
    pathTotal: maxWeight,
    forward: forward.current,
    allSteps: enhancedSteps
  };

  const header = renderBefore?.(props);
  let step = Component ? <Component {...props} /> : renderStep?.(props);
  const footer = renderAfter?.(props);

  if (wrapStep) step = wrapStep(props, step);

  return (<>
    {header}
    {step}
    {footer}
  </>);
};