import { DialogProps } from "@material-ui/core";

import { ConfirmDialog } from '../../shared/components/ConfirmDialog';

interface ExitDialogProps extends Omit<DialogProps, "onBackdropClick"> {
  onConfirm: () => void;
  onCancel: () => void;
}

export const ExitDialog = ({ onConfirm, onCancel, ...rest }: ExitDialogProps) => {

  return (
    <ConfirmDialog
      {...rest}
      onConfirm={onConfirm}
      onCancel={onCancel}
      dialogTitle="Exit?"
      dialogMessage="Are you sure you want to exit this CMA flow? You will lose all progress on this request."
    />
  );
}