import { getQueryObject } from "./getQueryObject";

export const useEmbed = () => {
  const {
    mbd,
  } = getQueryObject(window.location.search);

  if (mbd === "1" || mbd === "true") {
    localStorage.setItem("mdb", "1");
    return true;
  }
  else if (mbd === "0" || mbd === "false") {
    localStorage.setItem("mdb", "0");
    return false;
  }
  else {
    return localStorage.getItem("mdb") === "1";
  }
}

export const getEmbed = useEmbed;
