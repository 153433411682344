import * as React from 'react';
import { InputAdornment, OutlinedInput } from "@material-ui/core";
import { A8Button, InputContainer } from "av8-ui";
import { Controller, useForm } from "react-hook-form";
import { StateTextField } from "../../../shared/components/StateTextField";
import { RequestFlowControls, RequestFlowStepProps } from "../../RequestFlow";
import { ButtonRadioGroupRenderer } from "../buttonRadioGroupRender";
import { OutlinedTextFieldRenderer } from "../outlinedTextFieldRender";
import { NumberFormatCustom } from '../../../shared/components/NumberFormat';
import { StepTitle } from "../StepTitle";
import { getErrorMessage } from "../getErrorMessage";
import { cTInt } from "../converters";
import { AddressTextField } from './AddressTextField';
import { requiredDelayed } from '../reactHookFormValidations';
import { RequiredSymbol } from '../RequiredSymbol';
import { RequestFlowState } from '../../RequestFlowState';


type FormData = {
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zip?: string;
  propertyType?: string;
  multiFamilyUnits?: string;
  beds?: string;
  baths?: string;
  livingSpace?: string;
}

export const propertyTypeOptions = [
  { label: 'Condo', value: 'condo' },
  { label: 'Co-op', value: 'co-op' },
  { label: 'Multi Family', value: 'multi-family' },
  { label: 'Single Family', value: 'single-family' },
  { label: 'Townhouse', value: 'townhouse' },
  { label: 'TIC', value: 'tic' },
  { label: 'Land', value: 'land' },
  { label: 'Other', value: 'other' },
]

function fromFormData(data: FormData): RequestFlowState["compsDetails"] {
  const {
    addressLine1,
    addressLine2,
    baths,
    beds,
    city,
    livingSpace,
    multiFamilyUnits,
    propertyType,
    state,
    zip,
  } = data;
  return {
    addressLine1,
    addressLine2,
    baths: baths ? parseFloat(baths) : undefined,
    beds: beds ? parseInt(beds) : undefined,
    city,
    livingSpace: cTInt(livingSpace),
    multiFamilyUnits,
    propertyType,
    state,
    zip,
  };
}

function toFormData(data: RequestFlowState["compsDetails"]): FormData {
  const {
    addressLine1,
    addressLine2,
    baths,
    beds,
    city,
    livingSpace,
    multiFamilyUnits,
    propertyType,
    state,
    zip,
  } = data ?? {};
  return {
    addressLine1,
    addressLine2,
    baths: baths?.toString(),
    beds: beds?.toString(),
    city,
    livingSpace: livingSpace?.toString(),
    multiFamilyUnits,
    propertyType,
    state,
    zip,
  };
}

const normalizeToInt = (str?: string) => {
  if (str == null) return "";
  const r = parseInt(str);
  if (isNaN(r) || r <= 0) return "";
  return r.toString();
}
const normalizeToFloat = (fixedLength: number, step?: number) => (str?: string) => {
  if (str == null) return "";
  let r = parseFloat(str);
  if (isNaN(r) || r <= 0) return "";
  if (step) {
    r = Math.round(r / step) * step;
  }
  return r.toFixed(fixedLength).replace(/[.]0*$/, '');
}

const normlizeToStep = normalizeToFloat(1, 0.5);

const ENABLE_ADDRESS_SEARCH = true;

export const SetCompsDetailsStep = (props: RequestFlowStepProps) => {

  const flowState = props.lastSentState ?? props.state;
  const initialValues: FormData = toFormData(flowState.compsDetails) ?? {};

  const { handleSubmit, register, errors, control, watch, getValues, setValue, trigger } = useForm({ defaultValues: initialValues });

  const propertyType = watch("propertyType");

  const getModifiedFlowState = (data: FormData) => {
    return { ...props.state, compsDetails: fromFormData(data) };
  }

  const onSubmit = (data: FormData) => {
    props.moveTo("confirmCompsDetails", getModifiedFlowState(data));
  };

  const submitHandler = handleSubmit(onSubmit);

  const controls = <RequestFlowControls extractStateBeforeBack={() => getModifiedFlowState(getValues())} {...props} nextButton={<A8Button wide={true} variant="contained" type="submit" data-meta-action="next">Next</A8Button>} />;

  return (<form onSubmit={submitHandler} noValidate data-meta-name="step-2">
    {controls}
    <StepTitle primaryText="*Tell us about* the subject property." />

    { ENABLE_ADDRESS_SEARCH ? (
      <InputContainer label={<span>Address Line 1 <RequiredSymbol /></span>} padding="normal" error={getErrorMessage("addressLine1", errors)}>
        <AddressTextField
          onAddressFound={(address) => {
            setValue("addressLine1", address.terms?.[0] ?? "");
            setValue("city", address.city ?? "");
            setValue("state", address.state ?? "");
            setValue("zip", address.zipCode ?? "");
            trigger(['addressLine1', 'city', 'state', 'zip']);
          }}
          textFieldProps={{
            inputRef: register({ validate: requiredDelayed(getValues)("addressLine1") }),
            variant: "outlined",
            size: "small",
            error: !!errors.addressLine1,
            placeholder: "Address Line 1",
            name: "addressLine1",
            style: { padding: 0, margin: 0 },
            defaultValue: initialValues.addressLine1 ?? "",
            fullWidth: true,
            margin: "dense"
          }} />
      </InputContainer>
    ) : (
      <InputContainer label={<span>Address Line 1 <RequiredSymbol /></span>} padding="normal" error={getErrorMessage("addressLine1", errors)} >
        <OutlinedInput error={!!errors.addressLine1} placeholder="Address Line 1"
          name="addressLine1" inputRef={register({ validate: requiredDelayed(getValues)("addressLine1") })} defaultValue={initialValues.addressLine1 ?? ""}
          fullWidth={true} margin="dense" />
      </InputContainer >
    )}



    <InputContainer label="Address Line 2" padding="normal" error={getErrorMessage("addressLine2", errors)}>
      <OutlinedInput error={!!errors.addressLine2} placeholder="Address Line 2"
        name="addressLine2" inputRef={register()} defaultValue={initialValues.addressLine2 ?? ""}
        fullWidth={true} margin="dense" />
    </InputContainer>

    <InputContainer label={<span>City <RequiredSymbol /></span>} padding="normal" error={getErrorMessage("city", errors)}>
      <OutlinedInput error={!!errors.city} placeholder="City"
        name="city" inputRef={register({ validate: requiredDelayed(getValues)("city") })} defaultValue={initialValues.city ?? ""}
        fullWidth={true} margin="dense" />
    </InputContainer>

    <Controller name="state" defaultValue={initialValues?.state ?? ""} control={control} rules={{ required: true }}
      render={OutlinedTextFieldRenderer({ component: StateTextField, label: (<span>State <RequiredSymbol /></span>), errors, textFieldProps: { InputLabelProps: { required: true } } })} />

    <InputContainer label={<span>Zip <RequiredSymbol /></span>} padding="normal" error={getErrorMessage("zip", errors)}>
      <OutlinedInput error={!!errors.zip} placeholder="Zip"
        name="zip" inputRef={register({ validate: requiredDelayed(getValues)("zip") })} defaultValue={initialValues.zip ?? ""}
        fullWidth={true} margin="dense" />
    </InputContainer>

    <Controller name="propertyType" defaultValue={initialValues.propertyType ?? ""} rules={{ required: true }} control={control}
      render={ButtonRadioGroupRenderer({
        label: <span>What type of property is it? <RequiredSymbol /></span>,
        errors, buttonRadioGroupProps: {
          options: propertyTypeOptions
        },
        name: "propertyType"
      })} />

    {
      propertyType === 'multi-family' ? (
        <Controller name="multiFamilyUnits" defaultValue={initialValues.multiFamilyUnits ?? ""} rules={{ required: true }} control={control}
          render={ButtonRadioGroupRenderer({
            label: <span>Multi Family Units <RequiredSymbol /></span>,
            errors, buttonRadioGroupProps: {
              options: ["2-4", "5-6", "7-9", "10+"].map((x) => ({ label: x.toString(), value: x.toString() })),
            },
            name: "multiFamilyUnits"
          })} />
      ) : (null)
    }

    <Controller name="beds" defaultValue={initialValues.beds?.toString() ?? ""} rules={{ required: true }} control={control}
      render={ButtonRadioGroupRenderer({
        label: <span>How many bedrooms does the property have? <RequiredSymbol /></span>,
        errors,
        buttonRadioGroupProps: {
          options: [{ label: 'Studio', value: '0' }, ...[1, 2, 3, 4].map((x) => ({ label: x.toString(), value: x.toString() }))],
          buttonLineProps: { spacingY: '0.5rem' },
          normalizeCustomValue: normalizeToInt,
          customValuesEnabled: true,
          customValueProps: {
            label: "Custom",
            type: "number",
            inputProps: { step: 1, min: 0 },
            name: 'beds',
          }
        },
        name: "beds"
      })} />

    <Controller name="baths" defaultValue={initialValues.baths?.toString() ?? ""} rules={{ required: true }} control={control}
      render={ButtonRadioGroupRenderer({
        label: <span>How many bathrooms does the property have? <RequiredSymbol /></span>,
        errors,
        buttonRadioGroupProps: {
          options: [...[2, 3, 4, 5, 6, 7].map((x) => ({ label: (x * 0.5).toString(), value: (x * 0.5).toString() }))],
          buttonLineProps: { spacingY: '0.5rem' },
          customValuesEnabled: true,
          normalizeCustomValue: normlizeToStep,
          customValueProps: {
            label: "Custom",
            inputProps: { type: "number", step: 0.5, min: 0 },
            name: "baths"
          }
        },
        name: "baths"
      })} />

    <Controller name="livingSpace" rules={{ validate: requiredDelayed(getValues)("livingSpace") }} defaultValue={initialValues.livingSpace ?? ""} control={control}
      render={OutlinedTextFieldRenderer({
        errors, label: <span>What is the living space of the property? <RequiredSymbol /></span>,
        textFieldProps: { InputProps: { endAdornment: <InputAdornment position="end">sq.ft</InputAdornment>, inputComponent: NumberFormatCustom as any } }
      })} />
    <br />
    {controls}
  </form >);
}