import { TextField, TextFieldProps } from "@material-ui/core";
import { InputContainer } from "av8-ui";
import { ControllerRenderProps, DeepMap, FieldError } from "react-hook-form";
import { getErrorMessage } from "./getErrorMessage";

export const OutlinedTextFieldRenderer = ({
  textFieldProps, errors, label, errorMessage, notifyChange, component
}: {
  component?: any,
  notifyChange?: () => void,
  errorMessage?: string,
  errors?: DeepMap<Record<string, any>, FieldError>,
  label?: string | React.ReactNode,
  textFieldProps: TextFieldProps
}) => (props: ControllerRenderProps<Record<string, any>>
) => {
  const error = errors ? getErrorMessage(props.name, errors) : undefined;
    const TextFieldC = (component ?? TextField) as typeof TextField;
    const { InputProps, ...textFieldPropsRest } = textFieldProps
    return (
    <InputContainer padding="normal" label={label} error={error}>
      <TextFieldC
      {...textFieldPropsRest}
      variant="outlined"
      margin="none"
      InputProps={{ ...InputProps, margin:"dense" }}
      size="small"
      fullWidth={true}
      style={{margin: 0}}
      error={error != null}
      onChange={e => { props.onChange(e.target.value); notifyChange?.(); }}
      value={props.value}
      name={props.name}
    />
    </InputContainer>);
  }