import * as React from "react";
import { AuthData, AuthService } from "./auth-service";
// import { LegacyAuthService } from "./legacy-auth-service";
import { MockAuthService } from "./mock-auth-service";
import { SBLAuthService } from "./sbl-auth-service";

let _authService: AuthService;

if (process.env.NODE_ENV === 'development') {
  _authService = new MockAuthService();
}
else{
  _authService = new SBLAuthService();
}

export const authService = _authService;

const AuthContext = React.createContext(_authService);

export const useAuthContext = () => React.useContext(AuthContext);
export const AuthConsumer = AuthContext.Consumer;
export const AuthProvider = ({ children }: { children: React.ReactNode }) => (<AuthContext.Provider value={authService}>{children}</AuthContext.Provider>);

export const useAuthData = () => {
  const authContext = useAuthContext();
  const [cred, setCred] = React.useState(authContext.getCredentials());
  React.useEffect(() => {
    const listener = (data: AuthData | null) => {
      setCred(data);
    };
    authContext.addListener(listener);
    return () => { authContext.removeListener(listener); }
  })
  return cred;
}


