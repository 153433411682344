import { A8Button } from 'av8-ui';
import * as React from 'react';
import { ConfirmDialog } from "../../../shared/components/ConfirmDialog"

export interface NewClientExistsDialogProps {
  onYesClick: () => void;
  onNoClick: () => void;
  onCancel: () => void;
  open: boolean;
  existentClient?: { firstname: string, lastname: string, email: string }
}

export const NewClientExistsDialog = ({
  onYesClick,
  onNoClick,
  onCancel,
  open,
  existentClient
}: NewClientExistsDialogProps
) => {

  const { firstname, lastname, email } = existentClient || {};

  let dialogTitle: string = "Confirm?";
  let dialogMessage: string;
  const emailString = email ? ` and email address ${email}` : '';
  dialogMessage = `You already have a client with the name, ${firstname} ${lastname}${emailString}. Is this the same person?`;

  return <ConfirmDialog
    open={open}
    dialogTitle={dialogTitle}
    dialogMessage={dialogMessage}
    confirmLabel={"Yes"}
    onConfirm={onYesClick}
    extraButtons={<A8Button variant="contained" onClick={onNoClick} wide={true}>No</A8Button>}
    onCancel={onCancel}
  />
}