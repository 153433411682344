import { OutlinedInput } from "@material-ui/core";
import { A8Button, Radio, InputContainer, Checkbox } from "av8-ui";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { RadioGroupWrapper } from "../../../shared/components/RadioGroupWrapper";
import { RequestFlowControls, RequestFlowStepProps } from "../../RequestFlow";
import { getErrorMessage } from "../getErrorMessage";
import { required } from "../reactHookFormValidations";
import { RequiredSymbol } from "../RequiredSymbol";
import { StepTitle } from "../StepTitle";
import { SwitchIsNewClientDialog } from './SwitchIsNewClientDialog';
import { RequestFlowState } from "../../RequestFlowState";
import { ClientsTextField } from "./ClientsTextField";
import { SelectedClientCard } from "./SelectedClientCard";
import { CollapseFade } from "../../../shared/components/CollapseFade";
import { NewClientExistsDialog } from "./NewClientExistsDialog";
import { useNewClientBestMatchLogic } from "./useNewClientBestMatchLogic";
import { Fieldset } from "../../../shared/components/Fieldset";
import { useAgentClients } from "./useAgentClients";
import { ENABLE_CLIENT_DROPDOWN } from "../../flags";

type FormData = {
  isNewClient: boolean,
  newClient: {
    firstname: string;
    lastname: string;
    email: string;
  };
  existentClient: {
    id: number;
    firstname: string;
    lastname: string;
    email: string;
  };
  clientType: 'buyer' | 'seller';
}

export const SetClientStep = (props: RequestFlowStepProps) => {
  const flowState = props.lastSentState ?? props.state;
  const initialValues = {
    isNewClient: flowState.isNewClient,
    newClient: flowState.newClient || { email: "", firstname: "", lastname: "" },
    existentClient: flowState.existentClient ?? null,
    clientType: flowState.clientType
  };

  const [showSwitchIsNewClientDialog, setShowSwitchIsNewClientDialog] = useState(false);
  const { register, handleSubmit, errors, getValues, control, watch, setValue, formState } = useForm({ defaultValues: initialValues });
  const isNewClient = watch("isNewClient");
    const { loadingClients, clientList } = !ENABLE_CLIENT_DROPDOWN ? { loadingClients: false, clientList: [], } : useAgentClients(); //eslint-disable-line
  const newClientBestMatchLogic = !ENABLE_CLIENT_DROPDOWN ? { matchedClient: null, checkIfNewClientExists: () => null, promptOpen: false, dismissPrompt: () => { } } : useNewClientBestMatchLogic(clientList); //eslint-disable-line

  const getFlowModifiedState = (data: FormData) => {
    const { newClient, clientType, isNewClient, existentClient } = data;
    return {
      ...props.state,
      isNewClient,
      newClient: isNewClient ? newClient : undefined,
      existentClient: isNewClient ? undefined : existentClient,
      clientType
    } as RequestFlowState;
  }

  const onSubmit = async (data: FormData) => {
    if (data.isNewClient) {
      const match = await newClientBestMatchLogic.checkIfNewClientExists(data.newClient);
      if (match) {
        if (match.emailMatch) {
          data.existentClient = match.client;
          data.isNewClient = false;
        }
        else {
          //don't submit - the modal will pop up
          return;
        }
      }
    }
    props.moveTo("setCompsDetails", getFlowModifiedState(data));
  };

  const forceSubmit = (useMatchedClient: boolean) => {
    const data = getValues() as FormData;
    if (useMatchedClient) {
      data.isNewClient = false;
      data.existentClient = newClientBestMatchLogic.matchedClient?.client as any;
    }
    props.moveTo("setCompsDetails", getFlowModifiedState(data));
  }

  function setIsNewClientValue(isNewClient: boolean) {
    setValue("isNewClient", isNewClient);
  }

  const handleIsNewClientChange = (e: any) => {
    const { newClient, existentClient } = getValues();
    if (isNewClient && ((newClient?.firstname ?? "").length || (newClient?.email ?? "").length))
      setShowSwitchIsNewClientDialog(true);
    else if (!isNewClient && (existentClient != null))
      setShowSwitchIsNewClientDialog(true);
    else {
      setIsNewClientValue(!isNewClient);
    }
  }

  const submitHandler = handleSubmit(onSubmit);

  const controls = <RequestFlowControls {...props} nextButton={(<A8Button wide={true} variant="contained" type="submit" data-meta-action="next">Next</A8Button>)} />;

  return (<form onSubmit={submitHandler} noValidate data-meta-name="step-1">
    <Fieldset disabled={formState.isSubmitting}>
      {controls}
      <StepTitle primaryText="*Who are the* comps for?" />

      <InputContainer label={<span>Select one <RequiredSymbol /></span>} padding="normal" error={getErrorMessage("clientType", errors)}>
        <RadioGroupWrapper>
          <Radio ref={register({ required: true })} defaultChecked={initialValues.clientType === 'buyer'} value="buyer" name="clientType" label="Buyer" />
          <Radio ref={register({ required: true })} defaultChecked={initialValues.clientType === 'seller'} value="seller" name="clientType" label="Seller" />
        </RadioGroupWrapper>
      </InputContainer>

      {ENABLE_CLIENT_DROPDOWN && (
        <Controller
          control={control} name="existentClient" rules={{ required: !isNewClient }}
          defaultValue=""
          render={({ onChange, value }) => (
            <InputContainer error={!isNewClient ? getErrorMessage("existentClient", errors) : null}>
              <ClientsTextField
                clientList={clientList}
                selectedClient={value}
                disabled={isNewClient || loadingClients}
                onClientSelected={(client) => {
                  if (client?.id) {
                    const { firstName, lastName, ...rest } = client;
                    onChange({ firstname: firstName, lastname: lastName, ...rest });
                  }
                }}
                textFieldProps={{ variant: "outlined", size: "small", placeholder: "Search for a client", name: "searchClient", style: { padding: 0, margin: 0 }, fullWidth: true, margin: "dense" }}
              />
              {value && !isNewClient && (
                <CollapseFade in={true}>
                  <SelectedClientCard onRemoveClient={() => onChange(undefined)} firstName={value.firstname} lastName={value.lastname} email={value.email} />
                </CollapseFade>
              )}
            </InputContainer>
          )}
        />)}

      {!ENABLE_CLIENT_DROPDOWN ? (<Controller control={control} name="isNewClient" render={({ onChange, value }) => (
        <input type="hidden" checked={value} />)} />) : (
        <InputContainer label={"Do you want to add a new client?"} padding="normal">
          <RadioGroupWrapper>
            <Controller control={control} name="isNewClient" render={({ onChange, value }) => (
              <Checkbox label="Add a new client" checked={value} readOnly={true} onClick={handleIsNewClientChange} />)} />
          </RadioGroupWrapper>
        </InputContainer>)}

      {(
        <CollapseFade in={isNewClient}>
          <div>
            <InputContainer label="Email" padding="normal" error={getErrorMessage("newClient.email", errors)}>
              <OutlinedInput placeholder="Email" error={!!errors.newClient?.email} name="newClient.email" inputRef={register({
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Invalid email address."
                }
              })} type={"email"} defaultValue={initialValues?.newClient?.email ?? ""} fullWidth={true} margin="dense" />
            </InputContainer>

            <InputContainer label={<span>First Name <RequiredSymbol /></span>} padding="normal" error={getErrorMessage("newClient.firstname", errors)}>
              <OutlinedInput placeholder="First Name" error={!!errors.newClient?.firstname} name="newClient.firstname" inputRef={register({ validate: isNewClient ? required(getValues)("newClient.firstname") : undefined })} defaultValue={initialValues.newClient?.firstname ?? ""} fullWidth={true} margin="dense" />
            </InputContainer>

            <InputContainer label={<span>Last Name <RequiredSymbol /></span>} padding="normal" error={getErrorMessage("newClient.lastname", errors)}>
              <OutlinedInput placeholder="Last Name" error={!!errors.newClient?.lastname} name="newClient.lastname" inputRef={register({ validate: isNewClient ? required(getValues)("newClient.lastname") : undefined })} defaultValue={initialValues.newClient?.lastname ?? ""} fullWidth={true} margin="dense" />
            </InputContainer>
          </div>
        </CollapseFade>
      )}
      {<SwitchIsNewClientDialog
        open={showSwitchIsNewClientDialog}
        isNewCurrent={isNewClient}
        onCancel={() => setShowSwitchIsNewClientDialog(false)} onConfirm={() => {
          setIsNewClientValue(!isNewClient);
          setShowSwitchIsNewClientDialog(false);
        }} />
      }
      {<NewClientExistsDialog
        existentClient={newClientBestMatchLogic.matchedClient?.client || undefined}
        open={newClientBestMatchLogic.promptOpen}
        onCancel={() => {
          newClientBestMatchLogic.dismissPrompt();
        }}
        onNoClick={() => {
          forceSubmit(false);
        }}
        onYesClick={() => {
          forceSubmit(true);
        }}
      />}
      <br />
      {controls}
    </Fieldset>
  </form>);
}