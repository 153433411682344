import { useState } from "react";

interface Client {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
}

interface ClientDataSource {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
}

interface ClientQuery {
  firstname: string;
  lastname: string;
  email: string;
}

export const useNewClientBestMatchLogic = (clients: Array<ClientDataSource> | undefined) => {
  const [matchedClient, setMatchedClient] = useState<{ client: Client, emailMatch: boolean } | null>(null);
  const [prompt, setPrompt] = useState<boolean>(false);
  const [promptDismissed, setPrompDismissed] = useState<boolean>(false);
  const checkIfNewClientExists = async (query: ClientQuery) => {
    const canQuery = query.email?.length > 1
      || (
        query.firstname?.length > 1
        && query.lastname?.length > 1
      );
    if (!canQuery) {
      return null;
    }

    const client = await findClientBestMatch(query);
    const emailMatch = client?.email != null && !!query.email && client.email.trim() === query.email?.trim();

    if (!client) { return null; }

    setMatchedClient({ client, emailMatch });
    setPrompt(!emailMatch);
    setPrompDismissed(false);
    return { client, emailMatch };
  };

  async function findClientBestMatch({ email, firstname, lastname }: { email: string, firstname: string, lastname: string }) {
    firstname = firstname?.toLowerCase()?.trim();
    lastname = lastname?.toLowerCase()?.trim();
    email = email?.toLocaleLowerCase()?.trim();
    if (!clients) return;
    const match = clients.find((x: ClientDataSource) => (
      (
        !!x.firstName
        && x.firstName.toLowerCase() === firstname
        && !!x.lastName
        && x.lastName.toLowerCase() === lastname
      ) ||
      (
        !!email && x.email === email
      )
    )) || null;
    if (match == null) return null;
    if (match) {
      const { firstName: firstname, lastName: lastname, ...rest } = match;
      return { firstname, lastname, ...rest };
    }
  }

  const dismissPrompt = () => {
    setPrompDismissed(true);
  }

  return {
    promptOpen: prompt && !promptDismissed,
    matchedClient,
    checkIfNewClientExists: checkIfNewClientExists,
    dismissPrompt
  };
}