import styled from "styled-components";

export const AddressBar = styled.div`
    padding: 15px;
    display: flex;
    justify-content: center;
    background-color: #2A29A6;
    color: white;
    margin-bottom: 25px;
    font-size: 0.9rem;
    font-weight: 500;
    text-align: center;
`;