import { A8Button } from "av8-ui";
import { Controller, useForm } from "react-hook-form";
import { RequestFlowControls, RequestFlowStepProps } from "../RequestFlow";
import { ButtonRadioGroupRenderer } from "./buttonRadioGroupRender";
import { OutlinedTextFieldRenderer } from "./../components/outlinedTextFieldRender";
import { NumberFormatCustom } from '../../shared/components/NumberFormat';
import { StepTitle } from "./StepTitle";
import { InputAdornment } from "@material-ui/core";
import { cTInt } from "./converters";
import { RequiredSymbol } from "./RequiredSymbol";

type FormData = {
  hasAdditionalLivingSpace?: 'true' | 'false' | 'null' | '';
  estimatedAdditionalLivingSpace?: string;
}

export const SetAdditionalLivingSpaceStep = (props: RequestFlowStepProps) => {

  const initialValues = props.lastSentState ?? props.state;
  const formData = {
    hasAdditionalLivingSpace: initialValues.hasAdditionalLivingSpace === undefined ? '' : initialValues.hasAdditionalLivingSpace?.toString() ?? "null",
    estimatedAdditionalLivingSpace: initialValues.estimatedAdditionalLivingSpace?.toString() ?? ""
  }

  const { handleSubmit, errors, control, watch, getValues } = useForm({ defaultValues: formData });

  const hasAdditionalLivingSpace = watch('hasAdditionalLivingSpace');

  const getModifiedFlowState = (data: FormData) => {
    const hasAdditionalLivingSpace = (data.hasAdditionalLivingSpace === "null") ? null : data.hasAdditionalLivingSpace === 'true';
    const estimatedAdditionalLivingSpace = cTInt(data.estimatedAdditionalLivingSpace);
    return { ...props.state, hasAdditionalLivingSpace, estimatedAdditionalLivingSpace };
  }

  const onSubmit = (data: FormData) => {
    props.moveTo("setPropertyRenovated", getModifiedFlowState(data));
  };

  const submitHandler = handleSubmit(onSubmit);

  const controls = <RequestFlowControls {...props} extractStateBeforeBack={() => getModifiedFlowState(getValues() as any)} nextButton={<A8Button wide={true} variant="contained" type="submit" data-meta-action="next">Next</A8Button>} />;

  return (<form onSubmit={submitHandler} noValidate data-meta-name="step-4">
    {controls}
    <StepTitle primaryText="*Is there any* additional living space?" />
    <Controller name="hasAdditionalLivingSpace"
      defaultValue={formData.hasAdditionalLivingSpace}
      rules={{ required: true }} control={control}
      render={ButtonRadioGroupRenderer({
        errors, label: <span>In addition to the main home, are there ADUs, guest houses, etc? <RequiredSymbol /></span>, buttonRadioGroupProps: {
          options: [
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
            { label: 'I\'m not sure', value: 'null' },
          ],
        },
        name: "hasAdditionalLivingSpace"
      })} />

    {hasAdditionalLivingSpace === 'true' && <Controller name="estimatedAdditionalLivingSpace" rules={{ required: true }} defaultValue={formData.hasAdditionalLivingSpace ?? ""} control={control}
      render={OutlinedTextFieldRenderer({ errors, label: <span>Estimate additional square footage. <RequiredSymbol></RequiredSymbol></span>, textFieldProps: { InputProps: { endAdornment: <InputAdornment position="end">sq.ft</InputAdornment>, inputComponent: NumberFormatCustom as any } } })} />}
    <br />
    {controls}
  </form>);
}