// import { A8Button, ButtonLine } from "av8-ui";
import { WizardStepProps } from "../../shared/components/Wizard";
import { RequestFlowState } from "../RequestFlowState";

export const DebugFlowState = (props: WizardStepProps<RequestFlowState>) => {
  const { state, lastSentState, name: stepName } = props;
  return (<>
    <pre>
      <code>{JSON.stringify({ stepName, state, lastSentState }, null, " ")}</code>
    </pre>
  </>);
}