import { useEffect } from 'react'
import { useAuthData } from '../authentication/AuthContext';

declare const window: any;

export const HeapIntegration = () => {
  const { agentId } = useAuthData() || {};
  useEffect(() => {
    if (window && window.heap) {
      if (agentId && agentId.toString().length) {
        window.heap.identify(agentId.toString())
      }
    }
  }, [agentId])

  return null;
}

interface Heap {
  track: (eventName: string, payload?: any) => void;
}

const nullHeap: Heap = { 
  track: (eventName: string, payload: any) => {
    console.log(`[Heap] Event: ${eventName}, Payload: ${payload ? JSON.stringify(payload) : "empty"}`);
  }
}

class DefaultHeap implements Heap {
  track(eventName: string, payload: any){
    if(!window.heap) console.warn("Heap is not ready");
    else{
      window.heap.track(eventName, payload);
    }
  }
}

class HeapCombined implements Heap {
  constructor(private heaps: Heap[]) { }
  track(eventName: string, payload: any){
    this.heaps.forEach(h => h.track(eventName, payload));
  }
}

let heapInstance: Heap;
const heapMode = process.env.REACT_APP_HEAP_MODE;
if(heapMode==="NULL" || !heapMode) heapInstance = nullHeap;
if(heapMode==="COMBINED") heapInstance = new HeapCombined([nullHeap, new DefaultHeap()]);
if(heapMode==="DEFAULT") heapInstance = new DefaultHeap();

export const useHeap = () => {
  return heapInstance;
}

export const getHeap = useHeap; //in case you need to use Heap events outside a functional component