/* tslint:disable */
/* eslint-disable */
/**
 * All APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SingleClientResponseDto
 */
export interface SingleClientResponseDto {
    /**
     * 
     * @type {number}
     * @memberof SingleClientResponseDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SingleClientResponseDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof SingleClientResponseDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof SingleClientResponseDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof SingleClientResponseDto
     */
    seriousnessOption: string;
    /**
     * 
     * @type {string}
     * @memberof SingleClientResponseDto
     */
    meetOption: string;
    /**
     * 
     * @type {string}
     * @memberof SingleClientResponseDto
     */
    firstAddressLine: string;
    /**
     * 
     * @type {string}
     * @memberof SingleClientResponseDto
     */
    secondAddressLine: string;
    /**
     * 
     * @type {string}
     * @memberof SingleClientResponseDto
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof SingleClientResponseDto
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof SingleClientResponseDto
     */
    zip: string;
    /**
     * 
     * @type {string}
     * @memberof SingleClientResponseDto
     */
    phone: string;
    /**
     * 
     * @type {number}
     * @memberof SingleClientResponseDto
     */
    agentId: number;
}

export function SingleClientResponseDtoFromJSON(json: any): SingleClientResponseDto {
    return SingleClientResponseDtoFromJSONTyped(json, false);
}

export function SingleClientResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SingleClientResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'seriousnessOption': json['seriousnessOption'],
        'meetOption': json['meetOption'],
        'firstAddressLine': json['firstAddressLine'],
        'secondAddressLine': json['secondAddressLine'],
        'city': json['city'],
        'state': json['state'],
        'zip': json['zip'],
        'phone': json['phone'],
        'agentId': json['agentId'],
    };
}

export function SingleClientResponseDtoToJSON(value?: SingleClientResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'seriousnessOption': value.seriousnessOption,
        'meetOption': value.meetOption,
        'firstAddressLine': value.firstAddressLine,
        'secondAddressLine': value.secondAddressLine,
        'city': value.city,
        'state': value.state,
        'zip': value.zip,
        'phone': value.phone,
        'agentId': value.agentId,
    };
}


