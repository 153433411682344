/* tslint:disable */
/* eslint-disable */
/**
 * All APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CMAClientDto,
    CMAClientDtoFromJSON,
    CMAClientDtoFromJSONTyped,
    CMAClientDtoToJSON,
    CreatePropertyDto,
    CreatePropertyDtoFromJSON,
    CreatePropertyDtoFromJSONTyped,
    CreatePropertyDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateCMARequestDto
 */
export interface CreateCMARequestDto {
    /**
     * 
     * @type {string}
     * @memberof CreateCMARequestDto
     */
    agentId: string;
    /**
     * 
     * @type {CMAClientDto}
     * @memberof CreateCMARequestDto
     */
    client: CMAClientDto;
    /**
     * 
     * @type {CreatePropertyDto}
     * @memberof CreateCMARequestDto
     */
    subjectProperty: CreatePropertyDto;
    /**
     * 
     * @type {string}
     * @memberof CreateCMARequestDto
     */
    subjectPropertyMlsId: string;
    /**
     * 
     * @type {number}
     * @memberof CreateCMARequestDto
     */
    estimatedPriceMin?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateCMARequestDto
     */
    estimatedPriceMax?: number;
    /**
     * 
     * @type {object}
     * @memberof CreateCMARequestDto
     */
    compsPreferences?: object;
}

export function CreateCMARequestDtoFromJSON(json: any): CreateCMARequestDto {
    return CreateCMARequestDtoFromJSONTyped(json, false);
}

export function CreateCMARequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCMARequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agentId': json['agentId'],
        'client': CMAClientDtoFromJSON(json['client']),
        'subjectProperty': CreatePropertyDtoFromJSON(json['subjectProperty']),
        'subjectPropertyMlsId': json['subjectPropertyMlsId'],
        'estimatedPriceMin': !exists(json, 'estimatedPriceMin') ? undefined : json['estimatedPriceMin'],
        'estimatedPriceMax': !exists(json, 'estimatedPriceMax') ? undefined : json['estimatedPriceMax'],
        'compsPreferences': !exists(json, 'compsPreferences') ? undefined : json['compsPreferences'],
    };
}

export function CreateCMARequestDtoToJSON(value?: CreateCMARequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agentId': value.agentId,
        'client': CMAClientDtoToJSON(value.client),
        'subjectProperty': CreatePropertyDtoToJSON(value.subjectProperty),
        'subjectPropertyMlsId': value.subjectPropertyMlsId,
        'estimatedPriceMin': value.estimatedPriceMin,
        'estimatedPriceMax': value.estimatedPriceMax,
        'compsPreferences': value.compsPreferences,
    };
}


