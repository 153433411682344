import { A8Button } from 'av8-ui';
import { authService } from '../../shared/authentication/AuthContext';
import styled from 'styled-components';

const Warning = styled.div`
  background: orange;
  color: white;
  text-align: center;
  padding: 8px;
  font-size: 0.8rem;
`;

export const NotAuthenticatedBar = () => (
  <Warning>
    You are not authenticated. &nbsp; <A8Button variant="contained-white" size="small" onClick={() => authService.requestAuthentication()}>Authenticate</A8Button>
  </Warning>
);