import { WizardStep } from "../shared/components/Wizard";
import { ConfirmCompsDetailsStep } from "./components/ConfirmCompsDetailsStep";
import { SendRequestStep } from "./components/SendRequestStep";
import { SetAdditionalLivingSpaceStep } from "./components/SetAdditionalLivingSpaceStep";
import { SetClientStep } from "./components/SetClientStep/SetClientStep";
import { SetCompsDetailsStep } from "./components/SetCompsDetailsStep/SetCompsDetailsStep";
import { SetCompsPreferencesStep } from "./components/SetCompsPreferencesStep";
import { SetExtraInformationStep } from "./components/SetExtraInformationStep";
import { SetPropertyRenovatedStep } from "./components/SetPropertyRenovatedStep";
import { RequestFlowState } from "./RequestFlowState";

export const requestFlowSteps: {
  [key: string]: WizardStep<RequestFlowState>;
} = {
  setClient: { //1
    component: SetClientStep,
    movesTo: ["setCompsDetails"],
  },
  setCompsDetails: { //2
    component: SetCompsDetailsStep,
    movesTo: ["confirmCompsDetails"],
  },
  confirmCompsDetails: { //3
    component: ConfirmCompsDetailsStep,
    movesTo: ["setAdditionalLivingSpace"]
  },
  setAdditionalLivingSpace: { //4
    component: SetAdditionalLivingSpaceStep,
    movesTo: ["setPropertyRenovated"]
  },
  setPropertyRenovated: { //5
    component: SetPropertyRenovatedStep,
    movesTo: ["setCompsPreferences"]
  },
  setCompsPreferences: { //6
    component: SetCompsPreferencesStep,
    movesTo: ["setExtraInformation"]
  },
  setExtraInformation: { //7
    component: SetExtraInformationStep,
    movesTo: ["sendRequest"]
  },
  sendRequest: { //8
    component: SendRequestStep,
  }
};