import * as React from 'react';
import { Route, Switch } from "react-router";
import { Link } from 'react-router-dom';
import { useEmbed } from "../shared/useEmbed";
import { AppActions } from "./appActions";
import { DesktopActions } from "./desktopActions";
import { RequestFlow } from "./RequestFlow";
import { customRouter } from "../shared/components/customRouter";


export const RequestFlowModule = () => {

  const embed = useEmbed();
  if (embed)
    return (<RequestFlowMobileModule />);
  else
    return (<RequestFlowDesktopModule />);
}

const RequestFlowMobileModule = () => {

  return (<Switch>
    <Route path="/account/cma/request" render={({ location }) => {
      return (<RequestFlow />);
    }} />
    <Route path="*" render={({ location }) => {
      AppActions.backToHome();
      return (<RequestFlow />);
    }} />
  </Switch>
  );
}


const RequestFlowDesktopModule = () => {
  const ref = React.useRef<any>(null);
  return (<Route path="*" render={(routeProps) => {
    return customRouter({
      routeProps,
      routes: [
        {
          // "/"
          match: ({ pathname }) => pathname === "/",
          render: () => { window.location.href = '/account/cma/request'; return (<p>Redirecting...</p>); }
        },
        {
          // "{anything}/logout"
          match: ({ pathname }) => new RegExp("/logout/?$").test(pathname),
          render: ({ history, currentNode }) => {
            DesktopActions.logout();
            history.replace('/account'); //forward to force refresh
            return currentNode;
          }
        },
        {
          // "/account/cma/request/{anything}"
          match: ({ pathname }) => new RegExp("/account/cma/request/?$").test(pathname),
          render: () => (<RequestFlow />)
        },
        {
          // "*"
          match: () => true,
          forceRefresh: true,
          render: ({ location }) => (
            <div style={{ margin: '1rem' }}>
              <p>{location.pathname}</p>
              <p>Go to: <Link to="/account/cma/request">/account/cma/request</Link></p>
            </div>
          )
        },
      ], ref
    });
  }} />);
}

