import styled from 'styled-components';

export const Fieldset = styled.fieldset`
  &:disabled{
    opacity: .5;
    pointer-events: none;
  }
  border-width: 0;
  padding: 0;
  margin: 0;
`;