import * as Sentry from "@sentry/browser";

export async function captureException(e: any) {
  if(await captureFetchResponse(e)) return;
  Sentry.captureException(e);
}

async function captureFetchResponse(e: any): Promise<boolean> {
  if (
    typeof e === "object" &&
    e.status &&
    typeof e.status === "number" &&
    e.text &&
    isFunction(e.text)
  ) {
    const text = await e.text();
    Sentry.captureException(text);
    return true;
  }
  return false
}

function isFunction(fnCandidate: any) {
  return fnCandidate && {}.toString.call(fnCandidate) === "[object Function]";
}