import { createMuiTheme } from "@material-ui/core/styles";
import { BookmaniaFontFamily, StyreneAFontFamily } from "./modules/shared/fonts";
import { BaseTheme } from "./modules/shared/theme";

export const defaultStyledTheme: BaseTheme = {
  primaryColor: '#2a29a6',
  headerFontFamily: BookmaniaFontFamily,
  fontFamily: StyreneAFontFamily,
};

export const defaultTheme = createMuiTheme({
  typography: {
    fontFamily: StyreneAFontFamily,
    h1: {
      fontFamily: BookmaniaFontFamily,
    },
    h2: {
      fontFamily: BookmaniaFontFamily,
    },
    h3: {
      fontFamily: BookmaniaFontFamily,
    },
    h4: {
      fontFamily: BookmaniaFontFamily,
    },
    h5: {
      fontFamily: BookmaniaFontFamily,
    },
    h6: {
      fontFamily: BookmaniaFontFamily,
    },
  },
  palette: {
    primary: {
      main: '#2a29a6',
    },
  },
});

