import * as React from 'react';
import Autocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import { TextField, TextFieldProps, Tooltip } from '@material-ui/core';
import { Checkmark } from '../../../shared/images/icons/Checkmark';
import styled from 'styled-components';
import { FilterOptionsState } from '@material-ui/lab';
//import { InputAdornment } from '@material-ui/core';


const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const EmailText = styled.div`
  font-size: 0.7rem;
  color: #747474;
`;

export interface Client {
  id: any,
  firstName: string,
  lastName: string,
  email: string
}

interface Props {
  textFieldProps: TextFieldProps;
  selectedClient?: Client;
  clientList?: Client[];
  onClientSelected?: (client?: Client) => void;
  disabled: boolean;
}

export const ClientsTextField = React.forwardRef((props: Props, ref) => {
  return (
    <Inner {...props} ref={ref} />
  );
});

const Inner = React.forwardRef(({ textFieldProps, onClientSelected, selectedClient, disabled, clientList }: Props, ref) => {

  // let options: Array<{ title: string, terms: string[], fromApi: boolean }> = (data?.map(x => {
  //   return { title: x.description, terms: x.terms.map(x => x.value), fromApi: true };
  // }) ?? []);

  const [inputValue, setInputValue] = React.useState<any>('');
  const [value, setValue] = React.useState<any>(selectedClient?.id ?? "");

  React.useEffect(() => {
    if (selectedClient?.id === undefined) {
      setValue("");
    }
  }, [selectedClient])

  const filterOptions = (options: Client[], state: FilterOptionsState<any>) => {
    const value: string = state.inputValue?.toLowerCase().replace(/\s+/g, ' ').trim();
    if(!value || value.length === 0) return options;
    const filteredOptions = options.filter(client => {
      const email = (client.email?.toLowerCase()??"");
      const firstname = (client.firstName?.toLowerCase()??"");
      const lastname = (client.lastName?.toLowerCase()??"");
      const names = [...firstname.split(" "), ...lastname.split(" ")];
      const values = value.split(" ");
      return email.startsWith(value) || values.every(v => names.some(name => name.startsWith(v)));
    });
    return filteredOptions;
  }

  //options = options.concat([{ title: valueRef.current, terms: [], fromApi: false }]);
  return <Autocomplete
    ref={ref}
    freeSolo={true}
    options={clientList ?? []}
    blurOnSelect={true}
    inputValue={inputValue}
    closeIcon={null}
    value={value}
    inputMode="search"
    disabled={disabled}
    onBlur={() => {
      setInputValue('');
    }}
    filterOptions={filterOptions}
    includeInputInList={false}
    getOptionLabel={(option) => `${option?.firstName} ${option?.lastName}`}
    onChange={async (event, newValue) => {
      setInputValue('');
      onClientSelected?.(newValue as any);
      setValue(newValue);
    }}
    onInputChange={(e, v) => {
      if (e) {
        setInputValue(v);
      }
    }}
    renderOption={(option, e) => {
      return (<OptionContainer>
        <div>{`${option?.firstName} ${option?.lastName}`}<EmailText>{option?.email}</EmailText></div>
        <span>{value?.id === option?.id ? <Checkmark /> : null}</span>
      </OptionContainer>)
    }}
    renderInput={(params: AutocompleteRenderInputParams) => {
      if(disabled) {
        return (
          <Tooltip
            title='Uncheck ‘Add a new client’ to search'
            placement='top'
          >
            <TextField 
              {...textFieldProps}
              {...params}
              inputProps={{
                style: {cursor: 'not-allowed'},
                ...params.inputProps,
              }}
            />
          </Tooltip>
        )
      }

      return (
        <TextField
          {...textFieldProps}
          {...params}
        // InputProps={{
        //   endAdornment: (
        //     <InputAdornment position="end">
        //       Search Icon
        //     </InputAdornment>
        //   ),
        // }}
        />
      )
    }}
  />
});