import { Typography } from 'av8-ui';
import styled from 'styled-components';

const MobileHeaderWrapper = styled.div`
  background: #2A29A6;
  display: flex;
  align-items: center;
  padding: 0.5rem;
`;

const LeftButtonsWrapper = styled.div`
  margin-right: auto;
  min-width: 22%;
  position: relative;
  top: -2px;
`;

const RightButtonsWrapper = styled.div`
  margin-left: auto;
  min-width: 22%;
  text-align: right;
`;

const Title = styled(Typography)`
  font-size: 1rem;
  flex: 1;
  text-align: center;
`;

interface Props {
  leftButtons?: React.ReactNode;
  title: string;
  rightButtons?: React.ReactNode;
}

export const MobileHeader = ({
  leftButtons,
  title,
  rightButtons,
}: Props) => {
  const renderButtons = leftButtons || rightButtons;
  return (
    <MobileHeaderWrapper>
      {renderButtons && <LeftButtonsWrapper>
        {leftButtons}
      </LeftButtonsWrapper>}
      <Title variant="Title" color="white">
        {title}
      </Title>
      {renderButtons && <RightButtonsWrapper>
        {rightButtons}
      </RightButtonsWrapper>}
    </MobileHeaderWrapper>
  );
}