import { cTInt } from "./converters";

const whitespace = /^\s+$/;

export const validRange = (getValues: (key: string) => any) => (
  minKey: string,
  maxKey: string
) => {
  return {
    validRange: async () => {
      await new Promise((r) => setTimeout(r, 1));
      const min: string = getValues(minKey);
      const max: string = getValues(maxKey);
      if (min != null && min !== "" && max != null && max !== "") {
        const maxI = cTInt(max) ?? 0;
        const minI = cTInt(min) ?? 0;
        return maxI >= minI;
      }
      return true;
    },
  };
};

export const required = (getValues: (key: string) => any) => (key: string) => {
  return {
    required: () => {
      const v: any = getValues(key);
      return v != null && v !== "" && !whitespace.test(v); // uncomment to improve validation && !/^ +$/.test(v);
    },
  };
};

export const requiredDelayed = (getValues: (key: string) => any) => (key: string) => {
  return {
    requiredDelayed: async () => {
      await new Promise((r) => setTimeout(r, 1));
      const v: any = getValues(key);
      return v != null && v !== "" && !whitespace.test(v); // uncomment to improve validation && !/^ +$/.test(v);
    },
  };
};

export const notEmptyArray = (getValues: (key: string) => any) => (key: string) => {
  return {
    requiredArray: () => {
      const v: any[] = getValues(key);
      if (v == null || v.length === 0) {
        return false;
      }
      return true;
    },
  };
};