/* tslint:disable */
/* eslint-disable */
/**
 * All APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ClientDto,
    ClientDtoFromJSON,
    ClientDtoToJSON,
    PaginatedSimpleClientResponseDto,
    PaginatedSimpleClientResponseDtoFromJSON,
    PaginatedSimpleClientResponseDtoToJSON,
    SingleClientResponseDto,
    SingleClientResponseDtoFromJSON,
    SingleClientResponseDtoToJSON,
} from '../models';

export interface ClientControllerCreateClientRequest {
    clientDto: ClientDto;
}

export interface ClientControllerGetClientByIdRequest {
    id: number;
}

export interface ClientControllerListByAgentRequest {
    agentId: number;
    page: number;
    limit: number;
}

export interface ClientControllerSearchByAgentRequest {
    agentId: number;
    term: string;
    page: number;
    limit: number;
}

export interface ClientControllerSimpleListByAgentRequest {
    agentId: number;
    page: number;
    limit: number;
}

export interface ClientControllerUpdateClientRequest {
    clientDto: ClientDto;
}

/**
 * 
 */
export class ClientsControllerApi extends runtime.BaseAPI {

    /**
     */
    async clientControllerCreateClientRaw(requestParameters: ClientControllerCreateClientRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clientDto === null || requestParameters.clientDto === undefined) {
            throw new runtime.RequiredError('clientDto','Required parameter requestParameters.clientDto was null or undefined when calling clientControllerCreateClient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/client`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClientDtoToJSON(requestParameters.clientDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async clientControllerCreateClient(requestParameters: ClientControllerCreateClientRequest): Promise<void> {
        await this.clientControllerCreateClientRaw(requestParameters);
    }

    /**
     */
    async clientControllerGetClientByIdRaw(requestParameters: ClientControllerGetClientByIdRequest): Promise<runtime.ApiResponse<SingleClientResponseDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientControllerGetClientById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/client/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SingleClientResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async clientControllerGetClientById(requestParameters: ClientControllerGetClientByIdRequest): Promise<SingleClientResponseDto> {
        const response = await this.clientControllerGetClientByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async clientControllerListByAgentRaw(requestParameters: ClientControllerListByAgentRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agentId === null || requestParameters.agentId === undefined) {
            throw new runtime.RequiredError('agentId','Required parameter requestParameters.agentId was null or undefined when calling clientControllerListByAgent.');
        }

        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling clientControllerListByAgent.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling clientControllerListByAgent.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/client/list/{agentId}`.replace(`{${"agentId"}}`, encodeURIComponent(String(requestParameters.agentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async clientControllerListByAgent(requestParameters: ClientControllerListByAgentRequest): Promise<void> {
        await this.clientControllerListByAgentRaw(requestParameters);
    }

    /**
     */
    async clientControllerSearchByAgentRaw(requestParameters: ClientControllerSearchByAgentRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.agentId === null || requestParameters.agentId === undefined) {
            throw new runtime.RequiredError('agentId','Required parameter requestParameters.agentId was null or undefined when calling clientControllerSearchByAgent.');
        }

        if (requestParameters.term === null || requestParameters.term === undefined) {
            throw new runtime.RequiredError('term','Required parameter requestParameters.term was null or undefined when calling clientControllerSearchByAgent.');
        }

        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling clientControllerSearchByAgent.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling clientControllerSearchByAgent.');
        }

        const queryParameters: any = {};

        if (requestParameters.term !== undefined) {
            queryParameters['term'] = requestParameters.term;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/client/search/{agentId}`.replace(`{${"agentId"}}`, encodeURIComponent(String(requestParameters.agentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async clientControllerSearchByAgent(requestParameters: ClientControllerSearchByAgentRequest): Promise<void> {
        await this.clientControllerSearchByAgentRaw(requestParameters);
    }

    /**
     */
    async clientControllerSimpleListByAgentRaw(requestParameters: ClientControllerSimpleListByAgentRequest): Promise<runtime.ApiResponse<PaginatedSimpleClientResponseDto>> {
        if (requestParameters.agentId === null || requestParameters.agentId === undefined) {
            throw new runtime.RequiredError('agentId','Required parameter requestParameters.agentId was null or undefined when calling clientControllerSimpleListByAgent.');
        }

        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling clientControllerSimpleListByAgent.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling clientControllerSimpleListByAgent.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/client/list-simple/{agentId}`.replace(`{${"agentId"}}`, encodeURIComponent(String(requestParameters.agentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedSimpleClientResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async clientControllerSimpleListByAgent(requestParameters: ClientControllerSimpleListByAgentRequest): Promise<PaginatedSimpleClientResponseDto> {
        const response = await this.clientControllerSimpleListByAgentRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async clientControllerUpdateClientRaw(requestParameters: ClientControllerUpdateClientRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clientDto === null || requestParameters.clientDto === undefined) {
            throw new runtime.RequiredError('clientDto','Required parameter requestParameters.clientDto was null or undefined when calling clientControllerUpdateClient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/client`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ClientDtoToJSON(requestParameters.clientDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async clientControllerUpdateClient(requestParameters: ClientControllerUpdateClientRequest): Promise<void> {
        await this.clientControllerUpdateClientRaw(requestParameters);
    }

}
