/* tslint:disable */
/* eslint-disable */
/**
 * All APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CMAClientDto
 */
export interface CMAClientDto {
    /**
     * 
     * @type {number}
     * @memberof CMAClientDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CMAClientDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CMAClientDto
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof CMAClientDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CMAClientDto
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof CMAClientDto
     */
    sourceId?: string;
    /**
     * 
     * @type {string}
     * @memberof CMAClientDto
     */
    type?: string;
}

export function CMAClientDtoFromJSON(json: any): CMAClientDto {
    return CMAClientDtoFromJSONTyped(json, false);
}

export function CMAClientDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CMAClientDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'sourceId': !exists(json, 'sourceId') ? undefined : json['sourceId'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function CMAClientDtoToJSON(value?: CMAClientDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'email': value.email,
        'phone': value.phone,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'sourceId': value.sourceId,
        'type': value.type,
    };
}


