import { Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core/styles';
import { defaultTheme } from './theme';
import './modules/shared/fonts/styrenea.scss';
import './modules/shared/fonts/bookmania.scss';
import './App.scss';
import { RequestFlowModule } from './modules/request-flow/RequestFlowModule';
import { StylesProvider } from '@material-ui/styles';
import { HeapIntegration } from './modules/shared/components/HeapIntegration';
import { initSentry } from './initSentry';
import { AuthProvider } from './modules/shared/authentication/AuthContext';

initSentry();

const App = () => {

  const contentRoutes = (
    <Switch>
      <Route exact path="*">
        <RequestFlowModule />
      </Route>
    </Switch>
  );

  return (
    <>
      <AuthProvider>
        <ThemeProvider theme={defaultTheme}>
          <StylesProvider injectFirst>
            <BrowserRouter>
              <div style={{ overflowX: 'hidden' }}>
                {contentRoutes}
              </div>
            </BrowserRouter>
          </StylesProvider>
        </ThemeProvider>
        <HeapIntegration />
      </AuthProvider>
    </>
  );
}

export default App;
