import packageJson from "../package.json";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { getEmbed } from "./modules/shared/useEmbed";
import { authService } from "./modules/shared/authentication/AuthContext";

export function initSentry() {
  Sentry.init({
    dsn:
      "https://9c54131c137e4b568e6a355eadc12106@o625166.ingest.sentry.io/5753713",
    integrations: [new Integrations.BrowserTracing()],
    release: packageJson.version,
    environment: process.env.NODE_ENV,
    beforeSend: (event, hint) => {
      const embed = getEmbed();
      const { agentId } = authService.getCredentials() || {};
      event.tags = event.tags || {};
      event.tags.embed = embed;
      if (agentId) event.user = { id: `agent-${agentId}` };
      return event;
    },

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
