import { ClientsControllerMockApi } from "../shared/apis/app/cma-clients-api";
import {
  RequestsControllerApi,
  ClientsControllerApi,
  Configuration,
  FetchParams,
} from "../shared/apis/app/generated";
import { authService } from "../shared/authentication/AuthContext";

const mockRequestFlow = process.env.NODE_ENV === 'development' && process.env.REACT_APP_REQUEST_FLOW_MOCK === '1';

function getConfig(){
  const apiUrl = process.env.REACT_APP_APP_BACKEND_API_URL;
  if (!apiUrl) throw new Error("REACT_APP_APP_BACKEND_API_URL is required");
  return new Configuration({ basePath: apiUrl });
}

const getPreMiddleware = async ({ init, url } : any) => {
  const { token = null } = authService.getCredentials() ?? {}
  const { headers } = init;
  const params: FetchParams = {
    init: {
      ...init,
      headers: { ...headers, Authorization: `Bearer ${token}` },
    },
    url,
  };
  return params;
}

export function getApi() {
  const apiUrl = process.env.REACT_APP_APP_BACKEND_API_URL;
  if (!apiUrl) {
    return null;
  }
  return new RequestsControllerApi(getConfig()).withPreMiddleware(getPreMiddleware);
}

export const getClientsApi: () => ClientsControllerApi = () => {
  if (mockRequestFlow) {
    console.log("Using ClientsControllerApi mock");
    return new ClientsControllerMockApi();
  } else {
    return new ClientsControllerApi(getConfig()).withPreMiddleware(getPreMiddleware);
  }
}
