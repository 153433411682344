import { Collapse, Fade, FadeProps } from "@material-ui/core"

export const CollapseFade = (props: FadeProps) => {
  const { children, ...rest } = props;
  return (
    <Fade {...rest}><div>
      <Collapse in={props.in}>
        {children}
      </Collapse>
    </div></Fade>
  );
}