import * as React from 'react';
import { InputProps, OutlinedInput } from "@material-ui/core";
import { A8Button, Typography } from "av8-ui";
import { useForm } from "react-hook-form";
import { RequestFlowControls, RequestFlowStepProps } from "../RequestFlow";
import { StepTitle } from './StepTitle';

type FormData = {
  extraInformation: string
}

const TextFieldWithLimitHint = React.forwardRef(({ maxLength, style: inputStyle, ...rest }: InputProps & { maxLength: number }, ref: any) => {

  const initValue: any = rest.value ?? rest.defaultValue ?? "";
  const [length, setLength] = React.useState<number>(initValue.toString().length);

  const InputPropsMod: InputProps = {
    ...rest,
    style: { ...inputStyle, paddingBottom: '2.2rem' },
    onInputCapture: (e: any) => {
      let value: string = e.target?.value ?? "";
      if (value.length > maxLength) {
        value = value.substring(0, Math.min(maxLength, value.length));
      }
      if (e.target) e.target.value = value;
      rest?.onInputCapture?.(e);
      setLength(value.length);
    }
  }

  return (<div style={{ position: 'relative' }}>
    <OutlinedInput {...InputPropsMod} ref={ref} />
    <Typography
      as={"span" as any} variant="Caption"
      style={{ position: 'absolute', right: '0.8rem', bottom: '0.8rem', background: 'white', borderRadius: '5px', padding: '0.2rem', color: length > maxLength ? '#f44337' : 'gray' }}>
      {length}/{maxLength}
    </Typography>
  </div>)
});

export const SetExtraInformationStep = (props: RequestFlowStepProps) => {

  const data = props.lastSentState ?? props.state;

  const extraInformation = data.extraInformation ?? "";
  const defaultValues: FormData = { extraInformation };

  const { handleSubmit, register, errors, getValues } = useForm({ defaultValues });

  const getModifiedFlowState = ({ extraInformation }: FormData) => {
    return { ...props.state, extraInformation };
  }

  const onSubmit = (data: FormData) => {
    props.moveTo("sendRequest", getModifiedFlowState(data));
  };

  const submitHandler = handleSubmit(onSubmit);

  const controls = <RequestFlowControls {...props} extractStateBeforeBack={() => getModifiedFlowState(getValues())} nextButton={<A8Button wide={true} variant="contained" type="submit" data-meta-action="request">Request</A8Button>} />;

  return (<form onSubmit={submitHandler} noValidate data-meta-name="step-7">
    {controls}
    <StepTitle primaryText="*Anything else* you need us to know?" />

    <Typography padding="normal" display="block" variant="Caption" color="grey">Tell us (optional)</Typography>

    <TextFieldWithLimitHint maxLength={500} error={!!errors.extraInformation}
      placeholder={'Things that are noteworthy about the property or further instructions for the Avenue 8 team.'}
      multiline={true}
      inputProps={{ style: { minHeight: '140px' } }}
      name="extraInformation" type="number" inputRef={register({ required: false, maxLength: 500 })} defaultValue={defaultValues.extraInformation}
      fullWidth={true} margin="dense" />
    <br />
    {controls}
  </form>);
}