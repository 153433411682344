import { DeepMap, FieldError } from "react-hook-form";

export function getErrorMessage<FormType>(
  key: string,
  errorsMap: DeepMap<Partial<FormType>, FieldError>
) {
  let errorPointer: any = errorsMap;
  const keys = key.split('.');
  for(let i = 0; i < keys.length; i++){
    if(!errorPointer) return undefined;
    errorPointer = errorPointer[keys[i]];
  }
  
  return getErrorMessageFromField(errorPointer);
}

export function getErrorMessageFromField(error?: FieldError) {
  if (!error) return undefined;
  if (error.type.startsWith("required")) return "Required field.";
  if(error.type==='validRange'){
     return "Max must be equal or greater than Min."
  }
  return error.message || "Invalid field.";
}
