
import { Dialog, DialogActions, DialogContent, DialogProps } from "@material-ui/core";
import { A8Button, ButtonLine, Typography } from "av8-ui";

export interface ConfirmDialogProps extends Omit<DialogProps, "onBackdropClick"> {
  onConfirm: () => void;
  confirmLabel?: string;
  onCancel: () => void;
  extraButtons?: React.ReactNode
  dialogMessage: React.ReactNode;
  dialogTitle: React.ReactNode;
}

export const ConfirmDialog = ({ onConfirm, confirmLabel, onCancel, dialogMessage, dialogTitle, extraButtons, ...rest }: ConfirmDialogProps) => {

  return (<Dialog maxWidth={"xs"} onBackdropClick={onCancel} {...rest}>
    <DialogContent>
      <div
        style={{ float: 'right', marginRight: '-0.5rem', marginTop: '-0.5rem' }}>
        <A8Button
          onClick={onCancel}
          variant="text"
          style={{ color: 'black' }}>
          Cancel
        </A8Button></div>
      <Typography as={"h2" as any} style={{ marginTop: 0 }} variant="Subheading">
        {dialogTitle}
      </Typography>
      <Typography as={"p" as any}
        style={{ textAlign: 'center', lineHeight: 1.6 }}
        variant="Body">
        {dialogMessage}
      </Typography>
      <div
        style={{ textAlign: 'center', marginTop: '1.5rem' }}
      >
        <ButtonLine spacing={"4px"}>
          <A8Button
            variant="contained"
            wide={true}
            onClick={onConfirm}>
            {confirmLabel || "Discard"}
          </A8Button>
          {extraButtons}
        </ButtonLine>
      </div>
    </DialogContent>
    <DialogActions></DialogActions>
  </Dialog>);
}
